import React, { useEffect, useState } from 'react'
import { faArrowRotateLeft, faChevronRight, faCircle, faCreditCard, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Modal } from 'react-bootstrap'
import { NumericFormat } from 'react-number-format'
import { Controller, useForm } from "react-hook-form";
import { ApiPaths } from '../../../utils';
import axios from 'axios';
const CrearCompraPos = ({ onSubmit, validar, total, errorMetodoPago, seterrorMetodoPago, totalServicioGuia, totalServicioVehiculo, totalSeguros }) => {
    const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm();
    const [abrirModal, setAbrirModal] = useState(false)
    const [medioPagos, setMedioPagos] = useState([])

    const sumarPrecio = valor => {
        const validarPrecioActual = watch('precio') || 0;
        const sumarvalores = valor + validarPrecioActual;
        setValue('precio', sumarvalores, { shouldValidate: true })
    }
    useEffect(() => {
        const traerMediosPagos = async () => {
            try {
                const { data } = await axios(ApiPaths.metodosPago)
                setMedioPagos(data)
            } catch (error) {
                setMedioPagos([])
                console.log(error)
            }
        }
        traerMediosPagos()
    }, [])

    const handleCerrarModal = () => {
        const metodoPago = watch('mediosPago');

        if (metodoPago === 'Datafono') {
            setValue('precio', total)
            setAbrirModal(false)
            handleSubmit(onSubmit)()
        } else {
            setAbrirModal(false)
        }
    };

    const handleMostrarModal = () => setAbrirModal(true);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} className={validar && 'disable-all'}>
                <div className='compra-container'>
                    <div className='compra-total'>
                        <p><b>Total</b></p><p><NumericFormat displayType="text" value={total} prefix={'$'} thousandSeparator={true} /></p>
                    </div>
                    <div className='compra-total-servicios'>
                        <p>Servicios Uton</p><p><NumericFormat displayType="text" value={totalServicioGuia} prefix={'$'} thousandSeparator={true} /></p>
                    </div>
                    <div className='compra-total-servicios'>
                        <p>Servicios Transporte</p><p><NumericFormat displayType="text" value={totalServicioVehiculo} prefix={'$'} thousandSeparator={true} /></p>
                    </div>
                    <div className='compra-total-servicios'>
                        <p>Poliza</p><p><NumericFormat displayType="text" value={totalSeguros} prefix={'$'} thousandSeparator={true} /></p>
                    </div>

                    <Form.Label className='mb-0'>Importe Recibido</Form.Label>
                    <Controller
                        control={control}
                        name="precio"
                        rules={{ required: true, min: total }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <NumericFormat prefix={'$'}
                                thousandSeparator={true}
                                defaultValue={0}
                                placeholder='Ingrese un precio'
                                className={`compra-total-input ${errors.precio && 'is-invalid'}`}
                                onValueChange={(e) => onChange(e.floatValue)} value={value} />
                        )}
                    />
                    <div className='compra-total-dinero'>
                        <div className='compra-total-dinero-container'>
                            <div className='compra-total-dinero-item' onClick={() => sumarPrecio(1000)}>
                                <FontAwesomeIcon icon={faMoneyBill1Wave} size="1x" style={{ marginRight: 0 }} />
                                <p>$1.000</p>
                            </div>
                            <div className='compra-total-dinero-item' onClick={() => sumarPrecio(2000)}>
                                <FontAwesomeIcon icon={faMoneyBill1Wave} size="1x" style={{ marginRight: 0 }} />
                                <p>$2.000</p>
                            </div>
                            <div className='compra-total-dinero-item' onClick={() => sumarPrecio(5000)}>
                                <FontAwesomeIcon icon={faMoneyBill1Wave} size="1x" style={{ marginRight: 0 }} />
                                <p>$5.000</p>
                            </div>
                            <div className='compra-total-dinero-item' onClick={() => sumarPrecio(10000)}>
                                <FontAwesomeIcon icon={faMoneyBill1Wave} size="1x" style={{ marginRight: 0 }} />
                                <p>$10.000</p>
                            </div>
                            <div className='compra-total-dinero-item' onClick={() => sumarPrecio(50000)}>
                                <FontAwesomeIcon icon={faMoneyBill1Wave} size="1x" style={{ marginRight: 0 }} />
                                <p>$50.000</p>
                            </div>
                            <div className='compra-total-dinero-item' onClick={() => sumarPrecio(20000)}>
                                <FontAwesomeIcon icon={faMoneyBill1Wave} size="1x" style={{ marginRight: 0 }} />
                                <p>$20.000</p>
                            </div>


                        </div>
                        <div className='compra-total-dinero-regresar' onClick={() => setValue('precio', 0)}>
                            <div className='compra-total-dinero-regresar-item'>
                                <FontAwesomeIcon icon={faArrowRotateLeft} size="1x" style={{ marginRight: 0 }} />
                            </div>
                        </div>
                    </div>
                    <div className='compra-total-dinero'>
                        <div className='compra-total-dinero-container'>
                            <div className='compra-total-dinero-item item-amarillo' onClick={() => sumarPrecio(50)}>
                                <FontAwesomeIcon icon={faCircle} size="1x" style={{ marginRight: 0 }} />
                                <p>$50</p>
                            </div>
                            <div className='compra-total-dinero-item item-amarillo' onClick={() => sumarPrecio(100)}>
                                <FontAwesomeIcon icon={faCircle} size="1x" style={{ marginRight: 0 }} />
                                <p>$100</p>
                            </div>
                            <div className='compra-total-dinero-item item-amarillo' onClick={() => sumarPrecio(200)}>
                                <FontAwesomeIcon icon={faCircle} size="1x" style={{ marginRight: 0 }} />
                                <p>$200</p>
                            </div>
                            <div className='compra-total-dinero-item item-amarillo' onClick={() => sumarPrecio(500)}>
                                <FontAwesomeIcon icon={faCircle} size="1x" style={{ marginRight: 0 }} />
                                <p>$500</p>
                            </div>
                        </div>
                    </div>

                    <div className={`btn  boton_estandar-visitantes-crear ${errorMetodoPago && ' is-invalid'}`} onClick={handleMostrarModal}>
                        <FontAwesomeIcon icon={faCreditCard} size="1x" style={{ marginRight: 0 }} />
                        <p>Medios de Pago</p>
                    </div>
                    <button className="btn  boton_estandar-visitantes" type="submit">
                        <p>Continuar</p>
                        <FontAwesomeIcon icon={faChevronRight} size="1x" className='btn-icon' />
                    </button>
                </div>
                <Modal show={abrirModal} onHide={handleCerrarModal} className='modal-compra-pos' >
                    <Modal.Header >
                        <Modal.Title>Medios de Pago</Modal.Title>
                        <button className='salir-modal' onClick={handleCerrarModal}>
                            Salir
                        </button>
                    </Modal.Header>
                    <Modal.Body>

                        {medioPagos && medioPagos.map(medio => (
                            <Controller
                                key={medio.nombre}
                                control={control}
                                rules={{ required: true, min: 1 }}
                                name="mediosPago"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <div className='modal-compra-pos-input'>
                                        <Form.Label className='mb-0'>{medio.nombre}</Form.Label>
                                        <Form.Check.Input type="radio" onChange={() => { onChange(medio.nombre); seterrorMetodoPago(false) }} checked={value == medio.nombre ? true : false} />
                                    </div>
                                )}
                            />
                        ))}
                    </Modal.Body>
                    <Modal.Footer >
                        <button variant="primary" onClick={handleCerrarModal}>
                            FINALIZAR COMPRA
                        </button>
                    </Modal.Footer>
                </Modal>

            </Form >

        </>
    )
}


export default CrearCompraPos