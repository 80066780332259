import React from 'react'
import { ApiPaths } from '../utils';
import SubirEditGuias from '../componentesAdmin/guias/SubirEditGuias';

const Guias = () => {
    return (
        <div className="container d-flex justify-content-center mt-5">
            <div className="card mb-4  w-75">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-10">
                            <div className='d-flex align-items-center '>
                                <h2 className='font-weight-light'>Crear Guia</h2>
                            </div>
                        </div>
                    </div>
                    <SubirEditGuias funcion="crear" baseUrl={ApiPaths.guias} urlIngresos={ApiPaths.ingresos} TituloBtn="Subir Guia" />
                </div>
            </div>
        </div>
    )
}

export default Guias