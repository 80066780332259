import React, { useState, useEffect, useContext } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import Header from '../componentes/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiPaths } from '../../utils.js';
import * as RUTAS from '../../constantes/rutas';
import { Form } from 'react-bootstrap';
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import UserContext from '../../context/UserContext';
const EditarUsuario = () => {

	const { state: usuarioSelect } = useLocation()
	const { user } = useContext(UserContext);
	const navigate = useNavigate()
	//errores
	const [error, setError] = useState({ activo: false })
	//almacena toda la data de  
	const [registro, setRegistro] = useState(usuarioSelect)
	const [claveNueva, setClaveNueva] = useState('')
	const [operadores, setOperadores] = useState([])

	useEffect(() => {
		const operadores = async () => {
			try {
				const operadores = await axios(ApiPaths.operadores, { params: { activo: true } });
				setOperadores(operadores.data);
			} catch (error) {
				setOperadores([])
			}
		}
		operadores()
	}, [])


	if (!usuarioSelect) return <p>Error al ingresar</p>
	//Busca Id de Operador para añadirlo a tabla de usuarios como relación


	const peticionPut = async (e) => {
		e.preventDefault();
		try {
			const { activo, nombre, tipo } = registro
			const f = new FormData()
			f.append('activo', activo.toString())
			f.append('nombre', nombre)
			f.append('tipo', tipo)
			f.append("METHOD", "PUT")
			await axios.post(ApiPaths.metodosPago, f, { params: { id: registro.id } })
			navigate(RUTAS.ADMIN_METODO_PAGO)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div>
			<Header titulo='Editar Metodo de pago' funcion='regresar' onClick={() => navigate(RUTAS.ADMIN_METODO_PAGO)} Pagina={'Editar Metodo de pago'} Agregar={true} />
			<div className="row">
				<div className="col-md-12">
					<Form onSubmit={peticionPut}>
						{error.activo ?
							<span className="error"> Error: {error.mensaje}</span> : null
						}
						<div><hr />
							<div className='custom-control custom-switch '>
								<input type='checkbox' name="activo" className='custom-control-input form-control-lg mb-4' id='customSwitches'
									checked={String(registro.activo) == "true" ? true : false}
									onChange={e => setRegistro({ ...registro, activo: e.target.checked })}
									readOnly
								/>
								<label className='custom-control-label' htmlFor='customSwitches'>Activar/Desactivar</label>
							</div>

							<MDBInput name="nombre"
								required
								className={error.nombre ? "form-control is-invalid" : null} outline
								label={'Nombre'}
								value={registro.nombre}

								onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
							/>

							<div className='mb-4'>
								<select
									className={error.tipo ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"}
									name="tipo"
									required
									value={registro.tipo}
									onChange={e => setRegistro({ ...registro, tipo: e.target.value })}>
									<option value={""}>Selecciona un tipo</option>
									<option value="Efectivo">Efectivo</option>
									<option value="Tarjeta Credito">Tarjeta Credito</option>
									<option value="PSE">PSE</option>

								</select>

							</div>

							<br />
							<div className="col text-center">
								<button
									type='submit'
									className="btn boton_estandar"
								>{`Editar Usuario`}
								</button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	)
}

export default EditarUsuario