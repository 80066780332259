import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ApiPaths, TIPO_SERVICIOS } from '../../utils';
import Header from '../componentes/Header'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket, faCalendarDays, faCreditCardAlt, faUsers, faUser, faBinoculars, faVanShuttle, faHouse, faTaxi, faCheck, faXmark, faClipboardList, faPrint, faFileDownload, faDownload, faFilePdf, faHiking } from '@fortawesome/free-solid-svg-icons'
import { NumericFormat } from 'react-number-format'
import moment from 'moment'
import ServicioAlimentacion from '../reservas/Servicios/ServicioAlimentacion';
import * as RUTAS from '../../constantes/rutas';
import { Image } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';
import TicketPrint from '../html/TicketPrint';
import { useReactToPrint } from "react-to-print";

const DetalleReserva = () => {
    const navigate = useNavigate()
    const params = useParams();
    const location = useLocation();

    const [servicios, setServicios] = useState([])
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = useState("old boring text");

    const componentRef = useRef();
    const componentPdfRef = useRef();
    const [serviciosTotal, setServiciosTotal] = useState()

    const senderoMultiple = serviciosTotal?.tipoIngreso?.senderoMultiple === 'true';

    const arreglarFechaPedido = (fecha) => moment(fecha).format("YYYY-MM-DD h:mm:ss")

    const calcularEdad = (fecha) => {
        if (fecha.trim() === "") return null;

        const nacimiento = moment(fecha);
        const hoy = moment();
        return hoy.diff(nacimiento, "years");
    }


    const verificarDatosIcon = (dato) => {
        if (dato.trim() === "") return <FontAwesomeIcon icon={faXmark} size="1x" style={{ marginRight: 0, color: 'rgb(255, 204, 0)' }} />

        return <FontAwesomeIcon icon={faCheck} className="textVerde" size="1x" style={{ marginRight: 0 }} />
    }

    const peticionTraeDatosCompletos = async () => {
        try {
            const res = await axios.get(ApiPaths.historialreservas, { params: { completo: 'true', id: params.id } })
            const respuestaData = res.data;
            setServiciosTotal({
                estado: respuestaData.estado,
                fechaReserva: respuestaData.fechaReserva,
                tipoIngreso: {
                    nombre: respuestaData.tipoingreso,
                    multipleDias: respuestaData.tipoIngresoMultiple,
                    senderoMultiple: respuestaData.tipoIngresoSenderoMultiple,
                },
                personas: JSON.parse(decodeURI(respuestaData.personas)),
                fechaReservaInicial: respuestaData.fechaReservaInicial,
                fechaReservaFinal: respuestaData.fechaReservaFinal,
                serviciosAlimentacion: {
                    serviciosAlimentacion: JSON.parse(decodeURI(respuestaData.serviciosAlimentacion)),
                    contarServicios: JSON.parse(decodeURI(respuestaData.contarServicios)),
                    totalServicioAlimentacion: parseInt(respuestaData.totalServicioAlimentacion),
                    restriccionesAlimenticias: respuestaData?.restriccionesAlimenticias
                },
                servicioVehiculo: JSON.parse(decodeURI(respuestaData.servicioVehiculo)),
                servicioGuia: JSON.parse(decodeURI(respuestaData.servicioGuia)),
                servicioGenericos: JSON.parse(decodeURI(respuestaData.serviciosHospedaje)),
                entradas: respuestaData.entradas,
                totalEntradas: parseInt(respuestaData.totalEntradas),
                totalServicioVehiculo: parseInt(respuestaData.totalServicioVehiculo),
                totalServicioGuia: parseInt(respuestaData.totalServicioGuia),
                totalServiciosGenericos: parseInt(respuestaData.totalServiciosGenericos),
                totalSeguros: parseInt(respuestaData.totalSeguros),
                total: parseInt(respuestaData.total),
                id: respuestaData.id,
                transaccionesEstado: respuestaData.transaccionesEstado,
                operadorImg: respuestaData.operadorImg,
                operador: respuestaData.operador,
                mediosPago: respuestaData.transaccionesMetodoPago,
                codigoReserva: respuestaData.codigoReserva,
                id_nuvei: respuestaData.id_nuvei,
                totalAbonos: parseInt(respuestaData?.totalAbonos),
                saldos: respuestaData?.saldos ? JSON.parse(decodeURI(respuestaData.saldos)) : null,
                senderosDias: respuestaData.senderosDias ? JSON.parse(decodeURI(respuestaData.senderosDias)) : null,
            })

            const servicios = await axios.get(ApiPaths.servicios + "?tipo_ingreso=" + (respuestaData.tipoingresoPadre ? respuestaData.tipoingresoPadre : respuestaData.id_tipoingreso))

            const serviciosAlimentacion = servicios.data.filter(({ tipo }) => tipo === TIPO_SERVICIOS.alimentacion);
            setServicios(serviciosAlimentacion)

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        peticionTraeDatosCompletos()
    }, [])
    // TODO: want to make this `null` default but TS complains
    const onBeforeGetContentResolve = React.useRef(Promise.resolve);

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint jj` called"); // tslint:disable-line no-console
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console

        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 500);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);



    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `Reserva - ${new Date()}`,
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true,

    });

    React.useEffect(() => {
        if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    if (!serviciosTotal) return <p> cargando...</p>

    return (
        <div>

            <Header titulo="Detalles de reserva" Agregar={true} informes funcion='regresar' onClick={() => navigate(`${RUTAS.HISTORIALRESERVAS}`)} >
                <button
                    onClick={handlePrint}
                    className="btn boton_estandar_blanco float-right"
                ><FontAwesomeIcon icon={faPrint} size="lg" />
                </button>
                <button
                    onClick={() => {
                        handlePrint(null, () => componentPdfRef.current);
                    }}
                    className="btn boton_estandar_blanco float-right"
                ><FontAwesomeIcon icon={faFilePdf} size="lg" />
                </button>
            </Header>
            <div ref={componentPdfRef}>
                <style>
                    {`@media print {body{
  margin: 5mm 5mm 5mm 5mm;
}}}`}
                </style>
                <div className='row headReserva'>
                    <div className='col-md-1'>
                        <FontAwesomeIcon icon={faTicket} size="3x" style={{ marginTop: 20 }} />
                    </div>
                    <div className='col-md-8'>
                        <span>Estado: <b className={`estados ${serviciosTotal.estado}`}>{serviciosTotal.estado}</b></span>
                        <h2><b>{serviciosTotal.tipoIngreso.nombre}</b></h2>
                        {serviciosTotal.codigoReserva && <> <span>Reserva: <b>{serviciosTotal.codigoReserva}</b> - TIPO R: <b>OPERADORA</b></span><br></br></>}
                        <span>Fecha de realización de la Reserva: <b>{arreglarFechaPedido(serviciosTotal.fechaReserva)}</b></span>
                    </div>
                    <div className='col-md-3'>
                        <b>Fecha de Reservas:</b>
                        <div className='FechaRerserva'>
                            <span>
                                <FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 10 }} />
                                {serviciosTotal.fechaReservaInicial}
                            </span>
                        </div>
                        {serviciosTotal.fechaReservaFinal && <div className='FechaRerserva'>
                            <span>
                                <FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 10 }} />
                                {serviciosTotal.fechaReservaFinal}
                            </span>
                        </div>}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4 columaReserva'>
                        <h5><FontAwesomeIcon icon={faCreditCardAlt} size="1x" style={{ marginRight: 0 }} /> Datos de pago:</h5>
                        <hr></hr>
                        <span><b>Total Reserva:</b></span>
                        <h3><b><NumericFormat displayType="text" value={serviciosTotal.total} prefix={'$'} thousandSeparator={true} /></b></h3>
                        {serviciosTotal.mediosPago && <>
                            <span><b>Metodo de Pago:</b></span>
                            <h3><b>{serviciosTotal.mediosPago}</b></h3>
                        </>}

                        {location?.state?.modificacionesReserva && serviciosTotal?.saldos?.saldoReserva ?
                            (<>
                                <span><b>Total Pago Pendiente:</b></span>
                                <h3><b><NumericFormat displayType="text" value={serviciosTotal.saldos.saldoReserva} prefix={'$'} thousandSeparator={true} /></b></h3>
                            </>) : null
                        }

                        {serviciosTotal?.saldos?.totalReservaAnterior ?
                            (<>
                                <span><b>Total Pago Realizado:</b></span>
                                <h3><b><NumericFormat displayType="text" value={serviciosTotal?.saldos?.totalReservaAnterior} prefix={'$'} thousandSeparator={true} /></b></h3>
                            </>) : null
                        }

                        {serviciosTotal?.totalAbonos > 0 ? (<>
                            <span><b>Total Abonos Utilizados:</b></span>
                            <h3><b><NumericFormat displayType="text" value={serviciosTotal.totalAbonos} prefix={'$'} thousandSeparator={true} /></b></h3>
                        </>) : null}


                        <div className='informacion-reserva-imagen'>
                            <Image
                                src={ApiPaths.operadores + serviciosTotal.operadorImg}
                            />
                            <div>
                                <p><b>Operador:</b></p>
                                <p>{serviciosTotal.operador}</p>
                            </div>
                        </div>

                        <QRCodeSVG value={serviciosTotal.codigoReserva} className='mt-3' size={170} />
                        <br /><br />
                        {serviciosTotal.id_nuvei ?
                            <span><b>Id Nuvei: </b>{serviciosTotal.id_nuvei}</span>
                            : null}
                    </div>
                    <div className='col-md-4 columaReserva'>
                        {senderoMultiple ?
                            <div>
                                <h5><FontAwesomeIcon icon={faHiking} size="1x" style={{ marginRight: 0 }} /> Senderos:</h5>
                                <hr></hr>
                                {serviciosTotal.senderosDias.map((sendero, index) => (
                                    <div>
                                        <b><FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 0 }} /> Dia {index + 1}</b>
                                        <p><b>Fecha: </b>{arreglarFechaPedido(sendero.fecha)}</p>
                                        <p><b>Sendero: </b> {sendero.sendero.nombre}</p>
                                    </div>
                                ))}

                                <hr></hr>
                            </div>
                            :
                            null}
                        <h5><FontAwesomeIcon icon={faUsers} size="1x" style={{ marginRight: 0 }} /> Visitantes:</h5>
                        <hr></hr>
                        {serviciosTotal.personas.map((persona, index) =>
                            <div key={persona.id} >
                                <b><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /> Visitante {index + 1}</b>
                                <p>{verificarDatosIcon(persona.numeroDocumento)} <b>Documento: </b>{persona.numeroDocumento}</p>
                                <p>{verificarDatosIcon(persona.primerApellido)} <b>Primer Apellido: </b>{persona.primerApellido} </p>
                                <p>{verificarDatosIcon(persona.segundoApellido)} <b>Segundo Apellido: </b>{persona.segundoApellido}</p>
                                <p>{verificarDatosIcon(persona.nombre)} <b>Primer Nombre: </b>{persona.nombre}</p>
                                {persona.segundoNombre && <p>{verificarDatosIcon(persona.segundoNombre)} <b>Segundo Nombre: </b>{persona.segundoNombre}</p>}
                                <p>{verificarDatosIcon(persona.fechaNacimiento)} <b>Edad: </b>{calcularEdad(persona.fechaNacimiento)}</p>
                                <p>{verificarDatosIcon(persona.tipoDocumento)} <b>Tipo Documento: </b>{persona.tipoDocumento}</p>
                                <p>{verificarDatosIcon(persona.codigoSeguro)} <b>Cod seguro: </b> {persona.codigoSeguro.length > 15 ? <>
                                    {persona.codigoSeguro.substring(0, 15)}...
                                    <a href={ApiPaths.reservas + persona.codigoSeguro} target="_blank" rel="noopener noreferrer" className='ml-2 rounded-pill btn btn-md m-0 px-3 py-2 z-depth-0 waves-effect btn-primary'><FontAwesomeIcon icon={faFileDownload} size="1x" style={{ marginRight: 0 }} /></a>
                                </> : persona.codigoSeguro} </p>
                                <hr></hr>
                            </div>
                        )}
                        <p><b>Total Entradas: </b><NumericFormat displayType="text" value={serviciosTotal.totalEntradas} prefix={'$'} thousandSeparator={true} /> </p>
                        <p><b>Total Poliza: </b><NumericFormat displayType="text" value={serviciosTotal.totalSeguros} prefix={'$'} thousandSeparator={true} /> </p>
                    </div>
                    <div className='col-md-4 columaReserva'>
                        <h5><FontAwesomeIcon icon={faTaxi} size="1x" style={{ marginRight: 0 }} /> Servicios:</h5>
                        <hr></hr>
                        {serviciosTotal.servicioVehiculo && serviciosTotal.servicioVehiculo.length > 0 ?
                            <div>
                                {
                                    serviciosTotal.servicioVehiculo ? serviciosTotal.servicioVehiculo.map((item, index) => item.cantidad > 0 &&
                                        <div key={index} className='mb-2'>
                                            <b><FontAwesomeIcon icon={faVanShuttle} size="1x" style={{ marginRight: 0 }} /> Ingreso de {item.nombre}</b>
                                            <p><b>Cantidad: </b>x{item.cantidad} </p>
                                            <p><b>Tarifa: </b><NumericFormat displayType="text" value={item.tarifa} prefix={'$'} thousandSeparator={true} /> </p>
                                            {item.extradata && item.extradata.map((conductor, indx) =>
                                                <div key={indx}>
                                                    {conductor.conductor && <p><b>Conductor ({indx + 1}): </b>{conductor.conductor} </p>}
                                                    {conductor.documento && <p><b>Documento: </b>{conductor.documento}</p>}
                                                    {conductor.placa && <p><b>Placa: </b>{conductor.placa}</p>}
                                                    {conductor.seguro && <p><b>Seguro: </b>{conductor.seguro}</p>}
                                                    {conductor.codReserva && <p><b>Codigo Reserva: </b>{conductor.codReserva}</p>}
                                                </div>
                                            )}

                                        </div>
                                    )
                                        : null
                                }
                                <p><b>Total: </b><NumericFormat displayType="text" value={serviciosTotal.totalServicioVehiculo} prefix={'$'} thousandSeparator={true} /> </p>
                                <hr></hr>
                            </div>
                            : null
                        }

                        <div>
                            <b><FontAwesomeIcon icon={faBinoculars} size="1x" style={{ marginRight: 0 }} /> Servicios Uton</b>
                            <p><b>Cantidad: </b>x{serviciosTotal.entradas} </p>
                            <p><b>Total: </b><NumericFormat displayType="text" value={serviciosTotal.totalServicioGuia} prefix={'$'} thousandSeparator={true} /> </p>
                            {serviciosTotal.servicioGuia && serviciosTotal.servicioGuia.map((item, index) =>
                                <div key={index}>
                                    <p className='mt-2'><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /><b> Guia ({index + 1}): </b></p>
                                    <p><b>Nombre: </b> {item.guia?.nombre} </p>
                                    {item?.guia?.codReserva ?
                                        <p><b>Codigo de reserva: </b> {item.guia?.codReserva} </p>
                                        :
                                        <>
                                            <p><b>Cedula: </b> {item.guia?.cedula} </p>
                                            <p ><b>Telefono: </b> {item.guia?.telefono} </p>
                                        </>}
                                </div>
                            )}
                            <hr></hr>
                        </div>


                        {serviciosTotal?.servicioGenericos?.servicioGenericos && serviciosTotal.servicioGenericos.servicioGenericos.length > 0 ?
                            <div>
                                <b><FontAwesomeIcon icon={faHouse} size="1x" style={{ marginRight: 0 }} /> Hospedaje</b>
                                <p><b>Cantidad: </b>x{serviciosTotal.servicioGenericos.cantidadTotal} </p>
                                <p className='mb-2'><b>Total: </b><NumericFormat displayType="text" value={serviciosTotal.totalServiciosGenericos} prefix={'$'} thousandSeparator={true} /> </p>
                                {serviciosTotal.servicioGenericos.servicioGenericos.map((item, index) =>
                                    <div className='mb-2' key={index}>
                                        <p><b>{item.nombre}</b></p>
                                        <p><b>Cantidad: </b>{item.cantidad}</p>
                                    </div>
                                )}
                                <p style={{ fontSize: '14px' }}><b>Nota: </b> El Staff tiene {serviciosTotal.servicioGenericos.cuposStaff} cupos de habitaciones gratuitas.</p>
                                <hr></hr>
                            </div>
                            : null
                        }
                    </div>
                </div>
                {serviciosTotal.serviciosAlimentacion && serviciosTotal.serviciosAlimentacion.serviciosAlimentacion.length > 0 && <div className='row'>
                    <div className='col-md-12 '>
                        <ServicioAlimentacion titulo={'Servicio de alimentacion'} serviciosTotal={serviciosTotal} servicios={servicios} readOnly detalle />
                    </div>
                </div>}
            </div >


            <div style={{ display: 'none' }}><TicketPrint ref={componentRef} {...serviciosTotal} /></div>
        </div>
    )
}

export default DetalleReserva