import React, { useEffect, useState } from "react";

import CampoServicio from "./CampoServicio";
import { ApiPaths } from "../../../utils";
import axios from "axios";
import Select from 'react-select'

export default function ServiciosGuianza({ titulo, servicios, tipoIngreso, actualizarServiciosGeneralesTotal, serviciosTotal, errors, value, cambiarServiciosTotal }) {

  const [servicesSelected, setServicesSelected] = useState({ ...servicios[0] });
  const [currentGuianzaServicios, setCurrentGuianzaServicios] = useState([]);
  const [guias, setGuias] = useState();
  const [serviciodeGuia, setServiciodeGuia] = useState([])
  const validarZonaPadre = serviciosTotal.tipoIngreso.padre ? parseInt(serviciosTotal.tipoIngreso.padre) : parseInt(serviciosTotal.tipoIngreso.id);
  const [isInvalid, setIsInvalid] = useState(serviciosTotal?.botonHabilitado || false)
  const senderoMultiple = serviciosTotal.tipoIngreso.senderoMultiple === 'true';

  const handlerSelectedGuide = (guide, idx, servicio) => {
    if (guide.id == "GuiaCodigo") {
      cambiarServiciosTotal({
        ...serviciosTotal,
        botonHabilitado: false
      })

    } else {
      cambiarServiciosTotal({
        ...serviciosTotal,
        botonHabilitado: true
      })
    }

    if (senderoMultiple) {
      const copiaGuias = [...currentGuianzaServicios]
      copiaGuias[idx]['guia'] = { ...guide, ids_tipoingresos: '' };
      setServiciodeGuia(copiaGuias)
      setCurrentGuianzaServicios(copiaGuias)
      actualizarServiciosGeneralesTotal({
        servicioGuia: copiaGuias,
      })
      /* const guias = serviciodeGuia
       guias[idx] = { ...servicesSelected, guia: { ...guide, ids_tipoingresos: '' } };
       setServiciodeGuia(guias)
        setCurrentGuianzaServicios(guias)
        actualizarServiciosGeneralesTotal({
          servicioGuia: guias,
        })*/

    } else {
      const guias = serviciodeGuia
      guias[idx] = { ...servicesSelected, guia: { ...guide, ids_tipoingresos: '' } };
      setServiciodeGuia(guias)
      setCurrentGuianzaServicios(guias)
      actualizarServiciosGeneralesTotal({
        servicioGuia: guias,
      })
    }
  }

  const addGuianzaService = () => {
    let guianza = servicios[0];
    currentGuianzaServicios.push(servicios[0]);
    setCurrentGuianzaServicios(Array.from(currentGuianzaServicios));
  }

  const addGuianzaServiceMultiple = (sendero) => {
    currentGuianzaServicios.push({ ...servicios[0], id_tipoingreso: sendero.senderoId });
    setCurrentGuianzaServicios(Array.from(currentGuianzaServicios));
  }

  const initGuianzaServices = () => {
    if (senderoMultiple) {
      const guianza = servicios[0];
      const items = []
      serviciosTotal.senderosDias.map((sendero) => {
        items.push({ ...guianza, id_tipoingreso: sendero.senderoId })
      })
      setCurrentGuianzaServicios(items);
    } else {
      let guianza = servicios[0];
      let cantidadSolicitada = Math.ceil(serviciosTotal.entradas / guianza.capacidad);
      let guianzasInicialesArr = Array(cantidadSolicitada).fill(guianza);
      setCurrentGuianzaServicios(guianzasInicialesArr);
    }
  }

  const peticionTraeDatos = async () => {
    try {
      const res = await axios.get(ApiPaths.guias + "?tipo_ingreso=" + (serviciosTotal.tipoIngreso.id))
      const response = res.data
      setGuias([
        ...response,
        { nombre: "Guia de otra reserva", id: "GuiaCodigo" }
      ])
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (value) {
      setCurrentGuianzaServicios(value)
      setServiciodeGuia(value)
      peticionTraeDatos();
    } else {
      peticionTraeDatos();
      initGuianzaServices();
    }
  }, []);

  const removerGuia = () => {
    currentGuianzaServicios.pop();
    setCurrentGuianzaServicios(Array.from(currentGuianzaServicios));

    actualizarServiciosGeneralesTotal({
      servicioGuia: serviciodeGuia
    })
  }

  const validarCodigoReserva = async (codigoReserva, servicioId, index) => {
    try {
      if (codigoReserva.length > 2) {
        const resp = await axios(ApiPaths.reservas, { params: { codigoReserva } });
        setIsInvalid(resp.data.length > 0)
        cambiarServiciosTotal({ ...serviciosTotal, botonHabilitado: resp.data.length > 0 })
        if (resp.data.length > 0) {

          const guias = serviciodeGuia
          guias[index]['guia']['codReserva'] = codigoReserva
          guias['tarifa'] = "0"
          setServiciodeGuia(guias)
          setCurrentGuianzaServicios(guias)
          actualizarServiciosGeneralesTotal({
            servicioGuia: guias,
          })
        }

      } else {
        setIsInvalid(false)
        cambiarServiciosTotal({ ...serviciosTotal, botonHabilitado: false })
      }
    } catch (error) {
      console.log(error)
    }

  }

  return (
    <div className={`CampoServicios row ${errors.servicioGuia && 'is-invalid'}`}>
      <h4 className='col-12 HeaderTablaServicios'>{titulo} para ({serviciosTotal.entradas}) visitantes</h4>
      {validarZonaPadre == 2 && <p className="servicios-guianza-nota">PUEDES SELECCIONAR UN GUIA QUE ACOMPAÑE TU GRUPO, PERO NO LO EXIME DE PAGAR LOS SERVICIOS UTON.</p>}
      {senderoMultiple ?
        serviciosTotal.senderosDias.map((sendero, index) => {
          const validarCantidadSendero = currentGuianzaServicios.filter((servicio) => servicio.id_tipoingreso == sendero.senderoId).length;

          if (sendero.sendero) {
            return (
              <div className="container-guia">
                <h6 className="text-center font-weight-bold">{sendero.nombre} - {sendero.sendero.nombre}</h6>
                <div className="d-flex  " key={index}>
                  {currentGuianzaServicios.map((servicio, idx) => {
                    if (servicio.id_tipoingreso == sendero.senderoId) {
                      return (
                        <ServiciosGuianzaMultiple key={idx} idx={idx} servicio={servicio} sendero={sendero} handlerSelectedGuide={handlerSelectedGuide} addGuianzaService={addGuianzaServiceMultiple} currentGuianzaServicios={currentGuianzaServicios} isInvalid={isInvalid} removerGuia={removerGuia} validarCodigoReserva={validarCodigoReserva} validarCantidadSendero={validarCantidadSendero} />
                      )
                    }
                  })}
                </div>
              </div>
            )
          }
        })
        :
        <>
          {currentGuianzaServicios.map((servicio, idx) =>
            <div className='col-4 px-2 ' key={idx}>
              <CampoServicio servicio={servicio} showButtons={false} >
                <div style={{ marginTop: "0px", paddingTop: "5px" }}>
                  {guias &&
                    <Select
                      options={
                        guias.map((guia, index) =>
                          ({ label: guia.nombre, value: guia.id, ...guia })
                        )}
                      placeholder="Nombre guia"
                      defaultValue={servicio.guia}
                      onChange={(e) => handlerSelectedGuide(e, idx)}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}     //important
                    />}

                </div>

                {servicio?.guia?.id == "GuiaCodigo" && (
                  <div style={{ marginTop: "15px" }} >
                    <input defaultValue={servicio?.guia?.codReserva} required onChange={(e) => validarCodigoReserva(e.target.value, servicio.id, idx)} className={`form-control mt-2 ${isInvalid ? 'is-valid' : 'is-invalid'}`} type="text" placeholder="Cod reserva" pattern={!isInvalid} />
                  </div>
                )
                }
              </CampoServicio>
            </div>

          )
          }

          <div className=" col-1 d-flex flex-column justify-content-center">
            {currentGuianzaServicios.length > 1 && <button className="addGuianzaBtn" type="button" onClick={removerGuia}>-</button>}
            <button className="addGuianzaBtn" type="button" onClick={addGuianzaService}>+</button>
          </div>

        </>}


    </div >
  )
}

const ServiciosGuianzaMultiple = ({ idx, servicio, handlerSelectedGuide, validarCantidadSendero, currentGuianzaServicios, removerGuia, addGuianzaService, sendero, isInvalid, validarCodigoReserva }) => {
  const [guias, setGuias] = useState();

  useEffect(() => {

    const peticionTraeDatos = async () => {
      try {
        const res = await axios.get(ApiPaths.guias + "?tipo_ingreso=" + sendero.senderoId)
        const response = res.data

        setGuias([
          ...response,
          { nombre: "Guia de otra reserva", id: "GuiaCodigo" }
        ])
      } catch (e) {
        console.error(e)
      }
    }
    peticionTraeDatos();

  }, [])

  return (
    <>
      <div className='col-4 px-2 ' key={idx}>
        <CampoServicio servicio={servicio} showButtons={false} >
          <div style={{ marginTop: "0px", paddingTop: "5px" }}>
            {guias &&
              <Select
                options={
                  guias.map((guia, index) =>
                    ({ label: guia.nombre, value: guia.id, ...guia })
                  )}
                placeholder="Nombre guia"
                defaultValue={servicio.guia}
                onChange={(e) => handlerSelectedGuide(e, idx, servicio)}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}     //important
              />}

          </div>
          {servicio?.guia?.id == "GuiaCodigo" && (
            <div style={{ marginTop: "15px" }} >
              <input defaultValue={servicio?.guia?.codReserva} required onChange={(e) => validarCodigoReserva(e.target.value, servicio.id, idx)} className={`form-control mt-2 ${isInvalid ? 'is-valid' : 'is-invalid'}`} type="text" placeholder="Cod reserva" pattern={!isInvalid} />
            </div>
          )
          }
        </CampoServicio>
      </div>
      <div className=" col-1 d-flex flex-column justify-content-center">
        {validarCantidadSendero > 1 && <button className="addGuianzaBtn" type="button" onClick={removerGuia}>-</button>}
        <button className="addGuianzaBtn" type="button" onClick={() => addGuianzaService(sendero)}>+</button>
      </div>

    </>
  )
}