import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const InformacionVisitantes = ({ reservaSeleccionada, verificarDatosIcon, calcularEdad }) => {
    let visitantesStaff = 1;
    return (
        <div className='columaReserva'>
            <h5><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /> Visitantes:</h5>
            <hr></hr>
            {reservaSeleccionada.personas.map((persona, index) => {
                return (<div key={persona.id} >
                    <b><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /> Visitante {index + 1}</b>
                    <p>{verificarDatosIcon(persona.numeroDocumento)} <b>Documento: </b>{persona.numeroDocumento}</p>
                    <p>{verificarDatosIcon(persona.primerApellido)}<b>Primer Apellido: </b>{persona.primerApellido} </p>
                    <p>{verificarDatosIcon(persona.segundoApellido)} <b>Segundo Apellido: </b>{persona.segundoApellido}</p>
                    <p>{verificarDatosIcon(persona.nombre)} <b>Primer Nombre: </b>{persona.nombre}</p>
                    {persona.segundoNombre && <p>{verificarDatosIcon(persona.segundoNombre)} <b>Segundo Nombre: </b>{persona.segundoNombre}</p>}
                    <p>{verificarDatosIcon(persona.fechaNacimiento)} <b>Edad: </b>{calcularEdad(persona.fechaNacimiento)}</p>
                    <p>{verificarDatosIcon(persona.tipoDocumento)} <b>Tipo Documento: </b>{persona.tipoDocumento}</p>
                    <p>{verificarDatosIcon(persona.aseguradora)} <b>Aseguradora: </b>{persona.aseguradora}</p>
                    <p>{verificarDatosIcon(persona.codigoSeguro)} <b>Cod seguro: </b>{persona.codigoSeguro}</p>
                    <hr></hr>
                </div>)
            }
            )}
            <h5><FontAwesomeIcon icon={faUsers} size="1x" style={{ marginRight: 0 }} /> Staff:</h5>
            <hr></hr>
            {reservaSeleccionada.servicioGuia ?
                <div>
                    {reservaSeleccionada.servicioGuia.map((item, index) => <div>
                        <p className='mt-2'><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /><b> Visitante {visitantesStaff++}</b></p>
                        <p><b>Nombre: </b> {item.guia.nombre} </p>
                        <p><b>Cedula: </b> {item.guia.cedula} </p>
                        <p ><b>Telefono: </b> {item.guia.telefono} </p>
                    </div>

                    )}
                    <hr></hr>
                </div> : null
            }
            {reservaSeleccionada.servicioVehiculo ?
                <div>
                    {
                        reservaSeleccionada.servicioVehiculo && reservaSeleccionada.servicioVehiculo.map((item, index) => {
                            if (item.cantidad > 0) {

                                return <div key={index} className='mb-2'>
                                    {item.extradata && item.extradata.map((conductor, indx) => <div>
                                        <b><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /> Visitante {visitantesStaff++}</b>
                                        {conductor.conductor ? <p><b>Nombre: </b>{conductor.conductor} </p> : <p><b>Nombre: </b> Conductor Ruta Circular  </p>}
                                        {conductor.documento && <p><b>Documento: </b>{conductor.documento}</p>}
                                        {conductor.placa && <p><b>Placa: </b>{conductor.placa}</p>}
                                        {conductor.seguro && <p><b>Seguro: </b>{conductor.seguro}</p>}
                                        <hr></hr>
                                    </div>
                                    )}

                                </div>
                            }
                        })
                    }
                </div>
                : null
            }

        </div >
    )
}

export default InformacionVisitantes