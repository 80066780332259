import React, { useMemo } from 'react'
import Tabla from '../componentes/Tabla';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import useTraeDatos from '../hooks/useTraeDatos';
import { NumericFormat } from 'react-number-format';

const VerAbonos = ({ baseUrl, select, seleccionaRegistro }) => {
	const [data, setData] = useTraeDatos(baseUrl, { id: select?.id })

	const total = useMemo(() => {
		let valorTotal = 0;
		data.map(item => {
			if (item.estado == 'activo') {
				if (item.tipo == "ingreso") {
					valorTotal += parseInt(item.valor)
				}
				if (item.tipo == "utilizado") {
					valorTotal -= parseInt(item.valor)
				}
			}
		})
		return valorTotal
	}, [data])

	const columns = [
		{
			title: "Fecha", field: "fecha"
		},
		{
			title: "Cod Reserva", field: "codigo_reserva"
		},
		{
			title: "Medio Pago", field: "medio_pago"
		},
		{
			title: "Valor", field: "valor", render: (data) => {
				if (data.tipo == 'ingreso') return (<div className='texto-verde'>
					<NumericFormat displayType="text" value={data.valor} prefix={'+'} thousandSeparator={true} />
				</div>)
				if (data.tipo == 'utilizado') return (<div className='texto-rojo'>
					<NumericFormat displayType="text" value={data.valor} prefix={'-'} thousandSeparator={true} />
				</div>)
			}
		},
		{
			title: "Ingreso", field: "usuario_nombre"
		},
		{
			title: "Operador", field: "operador_nombre"
		},
		{
			title: "Estado", field: "estado"
		},


	];
	const actions = [
		(item) => item.tipo === "ingreso" && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEdit} size="lg" /></div>,
			tooltip: "Editar",
			onClick: (event, rowData) => { seleccionaRegistro(item, 'editarAbonos') },

		})]

	return (
		<div>
			<div className='totales-reservas row'>
				<div className='total-reservas container-totales-reservas col-3' >
					<p>Total <b>Abono</b></p>
					<NumericFormat displayType="text" value={total} prefix={'$'} thousandSeparator={true} />
				</div>
			</div>
			<Tabla columns={columns} data={data} actions={actions} title={`Listado de Abonos de: ${select?.nombre}`} />
		</div>
	)
}

export default VerAbonos