import React, { useState, useEffect, useContext } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import Header from '../componentes/Header.js';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ApiPaths } from '../../utils.js';
import * as RUTAS from '../../constantes/rutas.js';
import { Form } from 'react-bootstrap';
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserContext from '../../context/UserContext.js';

import { faTachometerAlt, faCircleCheck } from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used


const Transaccion_estado = () => {

	//const { state: estado } = useLocation()
	const { user } = useContext(UserContext);
	const navigate = useNavigate()

	const location = useLocation();
	const estado = location.pathname.split('/').pop();

	const [searchParams] = useSearchParams();
	const codigoTransaccion = searchParams.get('codigo')
	const Fecha = searchParams.get('fecha')
	const Total = searchParams.get('total')


	if (estado === 'aprobado') {
		return (
			<div className='d-flex'>
				<div className='col-md-4 col-sm-12 col-xs-12'></div>
				<div className='col-md-4 col-sm-12 col-xs-12' style={{ animation: 'bounce 1s', paddingLeft: 40, paddingRight: 40 }}>

					<h1 style={{ marginTop: '20%', fontWeight: 'bold' }}><FontAwesomeIcon icon={faCircleCheck} size="1x" style={{ color: '#27ae60' }} /> Pago Aprobado</h1>
					{/* <p><b>Código de Transacción:</b> {codigoTransaccion}<br/>
				<b>Fecha y Hora:</b> {Fecha}
				<b>Total:</b> {Total}</p> */}
					<p>Cualquier duda en el proceso de Pago puedes escribirnos a nuestra linea oficial o puedes comunicarte al correo electronico</p>
					<p><b>Email:</b> reservasutnevados@gmail.com</p>
					<p><b>Tel:</b> 321-506-6031
					</p>
					<button
						onClick={() => navigate(`${RUTAS.ADMIN_RESERVA}`)}
						className="btn boton_estandar float-center"
					>Volver a Reservas
					</button>
				</div>
				<div className='col-md-4 col-sm-12 col-xs-12'></div>
			</div>
		)
	} else if (estado === 'rechazado') {
		return (
			<div className='d-flex'>
				<div className='col-md-4 col-sm-12 col-xs-12'></div>
				<div className='col-md-4 col-sm-12 col-xs-12' style={{ animation: 'bounce 1s', paddingLeft: 40, paddingRight: 40 }}>

					<h1 style={{ marginTop: '20%', fontWeight: 'bold' }}><FontAwesomeIcon icon={faCircleCheck} size="1x" style={{ color: '#c0392b' }} /> Pago Rechazado</h1>
					<p>Cualquier duda en el proceso de Pago puedes escribirnos a nuestra linea oficial o puedes comunicarte al correo electronico</p>
					<p><b>Email:</b> reservasutnevados@gmail.com</p>
					<p><b>Tel:</b> 321-506-6031</p>
					<button
						onClick={() => navigate(`${RUTAS.ADMIN_RESERVA}`)}
						className="btn boton_estandar float-center"
					>Volver a Reservas
					</button>
				</div>
				<div className='col-md-4 col-sm-12 col-xs-12'></div>
			</div>
		)
	} else if (estado === 'pendiente') {
		return (
			<div className='d-flex'>
				<div className='col-md-4 col-sm-12 col-xs-12'></div>
				<div className='col-md-4 col-sm-12 col-xs-12' style={{ animation: 'bounce 1s', paddingLeft: 40, paddingRight: 40 }}>

					<h1 style={{ marginTop: '20%', fontWeight: 'bold' }}><FontAwesomeIcon icon={faCircleCheck} size="1x" style={{ color: '#e67e22' }} /> Pago Pendiente</h1>
					<p>El proceso de pago se encuentra Pendiente, es posible que tarde unos minutos en que aparezca el estado final de la transacción</p>
					<p>Cualquier duda en el proceso de Pago puedes escribirnos a nuestra linea oficial o puedes comunicarte al correo electronico</p>
					<p><b>Email:</b> reservasutnevados@gmail.com</p>
					<p><b>Tel:</b> 321-506-6031</p>
					<button
						onClick={() => navigate(`${RUTAS.ADMIN_RESERVA}`)}
						className="btn boton_estandar float-center"
					>Volver a Reservas
					</button>
				</div>
				<div className='col-md-4 col-sm-12 col-xs-12'></div>
			</div>
		)
	}

}

export default Transaccion_estado