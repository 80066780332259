import React, { useEffect, useState } from "react";
import CampoServicio from "./CampoServicio";

import { METHODS_TYPES, ApiPaths } from "../../../utils";
import axios from "axios";
import moment from "moment/moment";

export default function ServiciosGenericos({ titulo, servicios, serviciosTotal, actualizarServiciosGeneralesTotal, errors, value }) {
  const [serviciosGenerales, setServiciosGenerales] = useState([])
  const [cuposDisponibles, setcuposDisponibles] = useState({ cupos: 0 })
  const [cupo, setCupo] = useState(parseInt(servicios[0].capacidad))
  const [dias, setDias] = useState([])
  const [cuposRequeridos, setCuposRequeridos] = useState(0)
  const [cuposStaff, setCuposStaff] = useState(0)
  const [cantidadDias, setCantidadDias] = useState(1)

  const handlerServicioUpdate = (servicio, method) => {
    const serviciosSeleccionado = [...serviciosGenerales];

    const existeServicio = serviciosSeleccionado.find(item => item.id == servicio.id)
    if (existeServicio) {
      const existeServicioIndex = serviciosSeleccionado.findIndex(item => item.id == servicio.id)
      if (METHODS_TYPES.adicionar === method) {
        serviciosSeleccionado[existeServicioIndex] = { ...servicio, cantidad: (existeServicio.cantidad ? existeServicio.cantidad : 0) + 1 }
      } else if (METHODS_TYPES.remover === method) {
        serviciosSeleccionado[existeServicioIndex] = { ...servicio, cantidad: (existeServicio.cantidad ? existeServicio.cantidad : 0) - 1 }
      }
    } else {
      serviciosSeleccionado.push({ ...servicio, cantidad: 1 })
    }

    setServiciosGenerales(serviciosSeleccionado)

    const costoTotal = serviciosSeleccionado.reduce((acumulador, actual) => acumulador + ((actual.cantidad * parseInt(actual.tarifa)) * cantidadDias || 0), 0);

    const cantidadTotal = serviciosSeleccionado.reduce((acumulador, actual) => actual.id == "1" ? acumulador : acumulador + (actual.cantidad || 0), 0);

    actualizarServiciosGeneralesTotal({ servicioGenericos: serviciosSeleccionado, totalServiciosGenericos: costoTotal, cantidadTotal: (cantidadTotal + cuposStaff), cuposDisponibles, cuposStaff })
  }

  const totalCantidadServicioGuia = serviciosTotal?.servicioGuia ? new Set(
    serviciosTotal?.servicioGuia
      .map(servicio => servicio.guia && servicio.guia.cedula) // Solo mapea si 'guia' existe
      .filter(cedula => cedula !== null && cedula !== undefined) // Filtra valores nulos y no definidos
  ).size
    : 0;

  const totalCantidadServicioVehiculo = serviciosTotal.servicioVehiculos
    ? serviciosTotal.servicioVehiculos.reduce((acc, obj) => {
      // Check if the vehicle has extradata and at least one object with a documento
      const hasDocumento = obj.extradata && obj.extradata.some(data => data.documento);
      // If hasDocumento is true, count the vehicle
      return acc + (hasDocumento ? 1 : 0);
    }, 0)
    : 0;

  useEffect(() => {
    if (value) {
      setServiciosGenerales(value.servicioGenericos)
      setcuposDisponibles(value.cuposDisponibles)
      traerDatosCalendario(moment(serviciosTotal.fechas[0]).format("YYYY-MM-DD"), moment(serviciosTotal.fechas[1], "YYYY-MM-DD").add('days', 5).format("YYYY-MM-DD"))


      setCuposStaff(totalCantidadServicioGuia + totalCantidadServicioVehiculo)
      setCuposRequeridos(totalCantidadServicioVehiculo + totalCantidadServicioGuia + serviciosTotal.entradas)
    } else {
      setServiciosGenerales(servicios)

      traerDatosCalendario(moment(serviciosTotal.fechas[0]).format("YYYY-MM-DD"), moment(serviciosTotal.fechas[1], "YYYY-MM-DD").add('days', 5).format("YYYY-MM-DD"))

      setCuposStaff(totalCantidadServicioGuia + totalCantidadServicioVehiculo)
      setCuposRequeridos(totalCantidadServicioVehiculo + totalCantidadServicioGuia + serviciosTotal.entradas)
    }
  }, [])

  const traerDatosCalendario = async (fechaInicial, fechaFinal) => {
    try {
      const fechaI = moment(serviciosTotal.fechas[0]);
      const fechaF = moment(serviciosTotal.fechas[1]).subtract(1, 'days');
      const fechasActuales = []
      let cantidadDias = 0

      for (var m = moment(fechaI); m.isBefore(fechaF); m.add(1, 'days')) {
        fechasActuales.push(m.format('YYYY-MM-DD'))
        cantidadDias++
      }
      setCantidadDias(cantidadDias)
      const { data } = await axios(ApiPaths.reservas, { params: { fechaInicial, fechaFinal, IdHospedaje: serviciosTotal.tipoIngreso.padre } })
      const dias = []
      let numero_menor = cupo;

      let alertaArr = JSON.parse(servicios[0].alertas)

      let Agota = alertaArr[0] // tiene rangofin
      let PocasExis = alertaArr[1]
      let EnExis = alertaArr[2] //tiene rangoini

      for (const [key, value] of Object.entries(data)) {

        const buscarFecha = fechasActuales.some(fecha => fecha == key)

        setDias(dias)
        if (parseInt(Agota.rangofin) > (cupo - value)) {
          dias.push({
            dia: moment(key).format("DD"),
            cupo: cupo - value,
            background: 'Rojo',
            backgroundDia: buscarFecha ? 'Azul' : 'Blanco'
          })

          if ((cupo - value) <= numero_menor && buscarFecha) {
            numero_menor = (cupo - value)
            setcuposDisponibles({ cupos: numero_menor, background: 'Rojo' })
          }

        } else if (parseInt(PocasExis.rangoini) <= (cupo - value) && parseInt(PocasExis.rangofin) >= (cupo - value)) {
          dias.push({
            dia: moment(key).format("DD"),
            cupo: cupo - value,
            background: 'Amarillo',
            backgroundDia: buscarFecha ? 'Azul' : 'Blanco',
            ColorDia: buscarFecha && 'TextBlanco'
          })

          if ((cupo - value) <= numero_menor && buscarFecha) {
            numero_menor = (cupo - value)
            setcuposDisponibles({ cupos: numero_menor, background: 'Amarillo' })
          }
        } else if (parseInt(EnExis.rangoini) <= (cupo - value)) {
          dias.push({
            dia: moment(key).format("DD"),
            cupo: cupo - value,
            background: 'Verde',
            backgroundDia: buscarFecha ? 'Azul' : 'Blanco',
            ColorDia: buscarFecha && 'TextBlanco'
          })
          if ((cupo - value) <= numero_menor && buscarFecha) {
            numero_menor = (cupo - value)
            setcuposDisponibles({ cupos: numero_menor, background: 'Verde' })
          }

        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div className={`CampoServicios row ${errors.serviciosHospedaje && 'is-invalid'}`}>
      <h4 className='col-12 HeaderTablaServicios'>{titulo} EcoHotel el Cisne</h4>
      <div className='col-12' >
        <p>Seleccione el numero de personas para hospedaje:</p>
      </div>
      <div className='col-12' >
        <div className="row">


          <div className="col-2 header-servicios-alimentacion">
            <div style={{ display: 'flex', marginBottom: 3, alignItems: 'center' }}>
              <span style={{ fontSize: 14 }}>Noches:</span>
            </div>

            <div style={{ display: 'flex', marginBottom: 3, alignItems: 'center' }}>
              <span style={{ fontSize: 14 }}>Cupos Disponibles</span>
            </div>

          </div>


          <div className="col-9 servicios-alimentacion-container" >

            <div style={{ display: 'flex' }}>
              {dias && dias.map(dia =>
                <div className="servicios-alimentacion" key={dia.dia.toString()}>
                  <div className={`servicios-alimentacion-dias ${dia.backgroundDia} ${dia.ColorDia} `}>
                    <span style={{ textAlign: 'center' }}>{dia.dia}</span>
                  </div>

                  <div className="servicios-alimentacion-cupos"   >
                    <div className={`${dia.background}`}>
                      <span style={{ textAlign: 'center' }}>{dia.cupo}</span>
                    </div>
                  </div>
                </div>

              )}

            </div>

          </div>

        </div>
      </div>
      <div className='col-12' >
        <div className="row">
          <div className={`col-lg-2 col-sm-4  px-2 py-4`}>
            <div className={`servicio-item p-0 ${cuposDisponibles?.background}`}>
              <div className='servicio-item_container hospedaje-item '>
                <div className='servicio-description'>
                  <h4 className='nombre'>Cupos Disponibles</h4>
                  <h3>{cuposDisponibles?.cupos}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className={`col-lg-2 col-sm-4  px-2 py-4`}>
            <div className={`servicio-item  p-0 Blanco`}>
              <div className='servicio-item_container hospedaje-item '>
                <div className='servicio-description'>
                  <h4 className='nombre'>Cupos Staff Obligatorios</h4>
                  <h3>{cuposStaff}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className={`col-lg-2 col-sm-4  px-2 py-4`}>
            <div className={`servicio-item  p-0 Blanco`}>
              <div className='servicio-item_container hospedaje-item '>
                <div className='servicio-description'>
                  <h4 className='nombre'>Total Cupos Obligatorios</h4>
                  <h3>{cuposRequeridos}</h3>
                </div>
              </div>
            </div>
          </div>
        </div></div>

      {serviciosGenerales && serviciosGenerales.map(servicio => {
        return (
          <div key={servicio.id} className={`col-lg-4 col-sm-4  px-2 py-4 `} >
            <CampoServicio className={'p-0'} servicio={servicio} handlerServicioUpdate={handlerServicioUpdate} nombre={servicio['nombre']} cuposDisponibles={serviciosTotal.entradas} />
          </div>
        )
      })}

      <div className='col-12 ' >
        <p className="mb-0"><b>Nota: </b>El refugio de alta montaña - Centro de Visitantes El Cisne establece habitaciones de acomodación tipo dormitorio, lo cual implica que en una misma habitación podrán coincidir grupos de diferentes agencias, siempre y cuando contraten la tarifa de habitación múltiple. En caso de requerir el servicio de alojamiento en habitación privada, favor especificar el requerimiento al momento de realizar la reserva, a fin de verificar disponibilidad y generar la liquidación correspondiente.</p>

      </div>
    </div >
  )
}