import React, { useContext, useEffect, useRef, useState } from 'react'
import Header from '../componentes/Header'
import moment from 'moment'
import axios from 'axios'
import { SUPERADMIN } from '../../constantes/roles'
import UserContext from '../../context/UserContext'
import { ApiPaths } from '../../utils';

import InformesPrint from '../html/InformesPrint'
import { useReactToPrint } from "react-to-print";
import MostrarInformes from './MostrarInformes'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const VerInformes = () => {
    const componentRef = useRef();
    const [tituloPag, setTituloPag] = useState("Informes")
    const [funcion, setFuncion] = useState('ver')
    const [startDate, setStartDate] = useState(new Date())
    const [finalFecha, setFinalFecha] = useState(new Date())
    const [informes, setInformes] = useState([])
    const { user } = useContext(UserContext);
    const [informe, setInforme] = useState([])
    const [titulo, setTitulo] = useState('')
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = useState("old boring text");
    const [operadores, setOperadores] = useState([])
    const [operador, setOperador] = useState()

    useEffect(() => {
        const operadores = async () => {
            try {
                const operadores = await axios(ApiPaths.operadores, { params: { activo: true } });
                setOperadores(operadores.data);
            } catch (error) {
                setOperador([])
            }
        }
        operadores()
    }, [])

    useEffect(() => {
        peticionTraeDatos()
    }, [startDate, finalFecha, operador])

    const peticionTraeDatos = async () => {
        try {
            if (parseInt(user.roll) != SUPERADMIN) {
                const resp = await axios.get(ApiPaths.informes, { params: { idUsuario: user.id_operador, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD") } })
                const zonas = Object.values(resp.data)
                return setInformes(zonas)
            }

            const resp = await axios.get(ApiPaths.informes, { params: { startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), operador } })
            const zonas = Object.values(resp.data)
            setInformes(zonas)

        } catch (error) {
            setInformes([])
            return error
        }

    }

    const imprimirServicios = async (id, servicio, titulo) => {
        try {
            setTitulo(titulo)
            if (parseInt(user.roll) != SUPERADMIN) {
                const resp = await axios.get(ApiPaths.informes, { params: { idUsuario: user.id_operador, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), idZona: id, servicio } })
                setInforme(resp.data)
                return setFuncion('editar')
            }

            const resp = await axios.get(ApiPaths.informes, { params: { startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), idZona: id, servicio, operador } })
            setInforme(resp.data)
            //  handlePrint()
            return setFuncion('editar')
        } catch (error) {
            console.log(error)
        }

    }

    // TODO: want to make this `null` default but TS complains
    const onBeforeGetContentResolve = React.useRef(Promise.resolve);

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint jj` called"); // tslint:disable-line no-console
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console

        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 500);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `Informes - ${new Date()}`,
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    React.useEffect(() => {
        if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    return (
        <div>
            <Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} informes={true} >
                <button
                    onClick={handlePrint}
                    className="btn boton_estandar_blanco float-right"
                ><FontAwesomeIcon icon={faClipboardList} size="lg" />
                </button>
            </Header>
            <hr />
            {
                funcion == 'ver' ?
                    <MostrarInformes startDate={startDate} setStartDate={setStartDate} finalFecha={finalFecha} setFinalFecha={setFinalFecha} setOperador={setOperador} user={user} operadores={operadores} informes={informes} imprimirServicios={imprimirServicios} />
                    :
                    <InformesPrint ref={componentRef} data={informe} titulo={titulo} />
            }


        </div>
    )
}

export default VerInformes