import React from 'react'
import Header from '../componentes/Header';
import { Importer, ImporterField } from 'react-csv-importer';

import 'react-csv-importer/dist/index.css';
import { idiomaCSV } from '../../constantes/global';
import axios from 'axios';
import { ApiPaths } from '../../utils';


const VerBackups = () => {
	return (
		<div>
			<Header titulo='Respaldo' />

			<Importer
				locale={idiomaCSV}
				dataHandler={async (rows) => {
					let formData = new FormData()
					formData.append('importarDatos', JSON.stringify(rows))
					formData.append('METHOD', 'POST')
					const resp = axios.post(ApiPaths.reservas, formData)
					await new Promise((resolve) => resolve(null, resp.data));

				}}
				chunkSize={10000} // optional, internal parsing chunk size in bytes
				defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
				restartable={true} // optional, lets user choose to upload another file when import is complete
				onStart={({ file, fields }) => {
					// optional, invoked when user has mapped columns and started import
					//console.log("starting import of file", file, "with fields", fields);
				}}
				onComplete={({ file, fields }) => {
					// optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
					//console.log("finished import of file", file, "with fields", fields);
				}}

			>
				<ImporterField name="fechaReserva" label="fechaReserva" />
				<ImporterField name="estado" label="estado" />
				<ImporterField name="fechaReservaInicial" label="fechaReservaInicial" />
				<ImporterField name="fechaReservaFinal" label="fechaReservaFinal" />
				<ImporterField name="total" label="total" />
				<ImporterField name="totalEntradas" label="totalEntradas" />
				<ImporterField name="totalSeguros" label="totalSeguros" />
				<ImporterField name="totalServicioAlimentacion" label="totalServicioAlimentacion" />
				<ImporterField name="totalServicioGuia" label="totalServicioGuia" />
				<ImporterField name="totalServicioVehiculo" label="totalServicioVehiculo" />
				<ImporterField name="totalServiciosGenericos" label="totalServiciosGenericos" />
				<ImporterField name="servicioVehiculo" label="servicioVehiculo" />
				<ImporterField name="servicioGuia" label="servicioGuia" />
				<ImporterField name="serviciosAlimentacion" label="serviciosAlimentacion" />
				<ImporterField name="serviciosHospedaje" label="serviciosHospedaje" />
				<ImporterField name="personas" label="personas" />
				<ImporterField name="contarServicios" label="contarServicios" />
				<ImporterField name="entradas" label="entradas" />
				<ImporterField name="id_usuario" label="id_usuario" />
				<ImporterField name="id_tipoingreso" label="id_tipoingreso" />
				<ImporterField name="saldos" label="saldos" />
				<ImporterField name="metodoPago" label="metodoPago" />
				<ImporterField name="codigoReserva" label="codigoReserva" />
			</Importer>
		</div>
	)
}

export default VerBackups