import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './paginas/estilos/estilos_admin.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// window.RenderApp = (config) => {
//     ReactDOM.render(<App _config={config}/>, document.getElementById('root'));
//  }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
