import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

const BotonesFormulario = ({ item, SeleccionarCategoria, Roll, acceso, detalle }) => {

    return (
        <div className="col text-center">
            {detalle === true ?
                <button
                    onClick={() => SeleccionarCategoria(item, 'detalle')}
                    className="btn boton_estandar_blanco"
                ><FontAwesomeIcon icon={faEye} size="lg" />
                </button>
                :
                <button
                    onClick={() => SeleccionarCategoria(item, 'editar')}
                    className="btn boton_estandar_blanco"
                ><FontAwesomeIcon icon={faEdit} size="lg" />
                </button>
            }


            {Roll == 'superadmin' || Roll == 'Admin' || acceso == 'full' ?
                <button
                    onClick={() => SeleccionarCategoria(item, 'eliminar')}
                    className="btn boton_estandar_blanco"
                ><FontAwesomeIcon icon={faTrashAlt} size="lg" />
                </button>
                : null}


        </div>
    )
}



export default BotonesFormulario