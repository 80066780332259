import React, { useEffect, useState } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap'
import * as RUTAS from '../../constantes/rutas';
import { toast } from 'react-toastify';


const SubirEditGuias = ({ baseUrl, data, setData, setFuncion, urlIngresos, registroSelect, TituloBtn, funcion }) => {
	const location = useLocation()
	const [registro, setRegistro] = useState(funcion === 'editar' ? { ...registroSelect, ids_tipoingresos: JSON.parse(registroSelect.ids_tipoingresos) } : { activo: true, ids_tipoingresos: [], imagePrev: null })
	const [tiposIngresos] = useTraeDatos(urlIngresos)
	const [error, setError] = useState({ activo: false })
	const [imagenOld, setImagenOld] = useState(registro.imagen)

	//Consulta para subir o crear los datos 
	const creaFormData = (registro, METHOD, NombreIma) => {

		let { id, nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagenNueva, nombreImaNueva, activo } = registro

		let f = new FormData()
		f.append('id', id)
		f.append('nombre', nombre)
		f.append('cedula', cedula)
		f.append('runt', runt)
		f.append('ids_tipoingresos', JSON.stringify(ids_tipoingresos))
		f.append('telefono', telefono)
		f.append('METHOD', METHOD)

		if (METHOD === 'PUT') {
			f.append('imagen', imagenNueva !== undefined ? imagenNueva : imagen)
			f.append('nombre_imagen', nombreImaNueva)
			if (imagenNueva !== undefined) { f.append('nombre_imagen_vieja', imagenOld) }
		} else if (METHOD === 'POST') {
			f.append('imagen', imagen)
			f.append('nombre_imagen', NombreIma)
		}

		if (location.pathname === RUTAS.GUIAS) {
			f.append('activo', 'false')
		} else {
			if (funcion === 'editar') {
				f.append('activo', activo)
			} else {
				f.append('activo', 'true')
			}
		}

		return f
	}

	const peticionPost = async (e) => {
		e.preventDefault()
		const fecha = moment(new Date()).format("YYYY-MM-DD-HH-mm-ss");

		let { nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagePrev } = registro

		let formato = imagen.type.replace('image/', '.');
		let NombreImagen = imagen.name.replace(/[^A-Z0-9]+/ig, "_");
		let RutaServ = 'images/'

		let formatosPermitidos = ['.png', '.jpeg', '.jpg']

		if (location.pathname === RUTAS.GUIAS) {
			if (nombre !== undefined && cedula !== undefined && runt !== undefined && telefono !== undefined && ids_tipoingresos !== undefined) {
				if (imagen.size < 2000000 && formatosPermitidos.includes(formato)) {

					let NombreIma = RutaServ + fecha + formato
					let f = creaFormData(registro, 'POST', NombreIma)

					await axios.post(baseUrl, f)
						.then(response => {
							setRegistro({
								nombre: '',
								cedula: '',
								runt: '',
								telefono: '',
								ids_tipoingresos: [],
								imagen: '',
								imagePrev: null
							})
							errores('Limpio', setError)
							toast.success('Guia creado con exito')
						}).catch(error => {
							console.log(error);
							toast.error('Error al crear el guia')
						})
				} else errores('Imagen', setError)
			} else errores('CampoVacio', setError)

		} else {
			if (!existeRegistro(data, nombre)) {
				if (nombre !== undefined && cedula !== undefined && runt !== undefined && telefono !== undefined && ids_tipoingresos !== undefined) {
					if (imagen.size < 2000000 && formatosPermitidos.includes(formato)) {

						let NombreIma = RutaServ + fecha + formato
						let f = creaFormData(registro, 'POST', NombreIma)

						await axios.post(baseUrl, f)

							.then(response => {
								console.log('result', response.data)
								let resultFormat = { ...response.data, imagePrev: NombreIma, imagen: NombreIma }
								setData([resultFormat, ...data])
								setRegistro({ activo: false })
								errores('Limpio', setError)
								setFuncion('ver')

							}).catch(error => {
								console.log(error);
							})
					} else errores('Imagen', setError)
				} else errores('CampoVacio', setError)
			} else errores('Existe', setError)

		}

	}

	const ProcedimientoPut = async () => {
		let { id, nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagenNueva, nombreImaNueva, activo } = registro

		let f = creaFormData(registro, 'PUT')

		await axios.post(baseUrl, f)

			.then(response => {

				let dataNueva = data
				dataNueva.map(item => {
					if (item.id === id) {
						item.nombre = nombre
						item.cedula = cedula
						item.runt = runt
						item.ids_tipoingresos = JSON.stringify(ids_tipoingresos)
						item.telefono = telefono
						item.imagePrev = imagen
						item.imagen = imagen
						item.activo = activo
						//cuando hay una imagen nueva
						if (imagenNueva !== undefined) {
							item.imagePrev = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
							item.imagen = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
						}
					}
				})
				setData(dataNueva)
				console.log('enviado')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const peticionPut = (e) => {
		e.preventDefault()

		let { nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagePrev, imagenNueva } = registro

		if (nombre !== '' && cedula !== '' && runt !== '' && telefono !== '' && ids_tipoingresos.length > 0) {
			if (imagenNueva !== undefined) {
				//Cuando Hay una imagen nueva
				let formatosPermitidos = ['.png', '.jpeg', '.jpg', '.JPEG']
				console.log('imagenNueva', imagenNueva)
				let formato = typeof (imagenNueva) !== "string" ? imagenNueva.type.replace('image/', '.') : null;
				//Pregunta si el formato y peso son correctos
				if (imagenNueva !== '' && imagenNueva.size < 2000000 && formatosPermitidos.includes(formato)) {
					ProcedimientoPut()
				} else { errores('ImagenIncorrecta', setError) }
			} else ProcedimientoPut()

		} else errores('CampoVacio', setError)

	}

	const estaonoArray = (dato) => {
		let array = registro.ids_tipoingresos
		let result = false
		array.map(item => { if (item === dato) { result = true } })
		return result
	}

	const subeIngresos = (id) => e => {
		let array = registro.ids_tipoingresos
		if (estaonoArray(id)) {
			let index = array.indexOf(id);
			if (index > -1) { array.splice(index, 1); }
		} else { array = [...array, id] }

		setRegistro({ ...registro, ids_tipoingresos: array })

	}

	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />
						<Form onSubmit={funcion === 'editar' ? peticionPut : peticionPost}>

							<div className='row'>

								<div className='col-md-9'>
									{funcion === 'editar' && <div className='custom-control custom-switch '>
										<input
											type='checkbox'
											className='custom-control-input form-control-lg mb-4'
											id='customSwitches'
											checked={String(registro.activo) == "true" ? true : false}
											onChange={e => setRegistro({ ...registro, activo: e.target.checked })}
										/>
										<label className='custom-control-label' htmlFor='customSwitches'>Activar/Desactivar</label>
									</div>}
									<MDBInput
										label="Nombre del Guia" name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
										value={registro.nombre}
										onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
									/>

									<div className="input-group">
										<div className="input-group-prepend">
										</div>
										<div className="custom-file">
											<input type="file"
												className="custom-file-input"
												id="inputGroupFile01"
												ria-describedby="inputGroupFileAddon01"
												name="imagen"
												required={location.pathname === RUTAS.GUIAS}
												accept="image/png, image/gif, image/jpeg"
												onChange={recibeImagen(registro, setRegistro, funcion)}
											/>
											<label className="custom-file-label">
												Seleccionar Logo (400px x 400px)
											</label>
										</div>
									</div>

									<div className="row">
										<div className='col-md-6'>

											<MDBInput label="Cedula" name="cedula" required className={error.campoClave ? "form-control is-invalid" : null} outline
												value={registro.cedula}
												onChange={e => setRegistro({ ...registro, cedula: e.target.value })}
											/>
										</div>
										<div className='col-md-6'>
											<MDBInput label="Rnt" name="runt" required className={error.campoClave ? "form-control is-invalid" : null} outline
												value={registro.runt}
												onChange={e => setRegistro({ ...registro, runt: e.target.value })}
											/>
										</div>
									</div>

									<div >
										<h5>Seleccione Disponibilidad:</h5>
										{tiposIngresos.map((item, index) => {
											if (item.nombre !== 'todo') {
												return (
													<div className="custom-control custom-checkbox custom-control-inline" key={index}>
														<input type="checkbox" className="custom-control-input" name={item.id} id={item.nombre}
															onChange={subeIngresos(item.id)} checked={estaonoArray(item.id)} />
														<label className="custom-control-label" htmlFor={item.nombre}>{item.nombre}</label>
													</div>
												)
											}
										})
										}
									</div>

								</div>
								<div className="col-md-3">
									<div className="FotoPrev">
										{funcion === 'editar' ?
											<img alt="imagen" src={
												registro.imagePrev === registro.imagen ?
													baseUrl + "/" + registro.imagePrev
													: registro.imagePrev
											} />
											:
											<img src={registro.imagePrev !== null ? registro.imagePrev : fotoVacia} />
										}
									</div>
								</div>
							</div>


							<MDBInput label="Telefono" name="telefono" required className={error.campoClave ? "form-control is-invalid" : null} outline
								value={registro.telefono}
								onChange={e => setRegistro({ ...registro, telefono: e.target.value })}
							/>

							<br />
							<div className="col text-center">
								<button type='submit' className="btn boton_estandar">{TituloBtn} </button>
							</div>
						</Form>
					</div>

				</div>
			</div>
		</div>
	)
}

export default SubirEditGuias