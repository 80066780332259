import { NumericFormat } from 'react-number-format';
import Tabla from '../componentes/Tabla';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faDollar, faDollarSign, faEye, faFilePen, faPencil, faTrashAlt, faX } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import UserContext from '../../context/UserContext';
import { SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';

const VerReservas = ({ reservas, seleccionaRegistro }) => {

	const { user } = useContext(UserContext);

	const Estados = (dato) => {
		if (dato === 'PENDIENTE') {
			return 'P'
		} else if (dato === 'APROBADO') {
			return 'A'
		} else if (dato === 'RECHAZADO') {
			return 'R'
		} else if (dato === 'CANCELADO') {
			return 'C'
		} else if (dato === 'COMPLETADO') {
			return 'C'
		}
	}

	const columns = [
		{
			title: 'Estado',
			render: (item) => <span className={`estados ${item.estado}`}>{Estados(item.estado)}</span>,
			width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "estado"
		},
		{
			title: 'Cod', render: (item) => `${item.codigoReserva}`,
			width: "10%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "codigoReserva"
		},
		{
			title: "Creación", field: "fechaReserva", width: "17%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Ingreso", field: "tipoingreso", width: "30%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Fecha Reserva", render: (item) => <>{item.fechaReservaInicial} {item.fechaReservaFinal && `- ${item.fechaReservaFinal}`}</>,
			width: "22%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "fechaReservaInicial"
		},
		{
			title: "Total", render: (item) => <NumericFormat displayType="text" value={item.total} prefix={'$'} thousandSeparator={true} />, width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "total"

		},
		{
			title: "Fecha Cancelación", field: "fechaReservaCancelacion", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Saldo", render: (item) => {
				if (item.saldos.trim() !== '') {
					const saldos = JSON.parse(decodeURI(item?.saldos))
					return (<NumericFormat displayType="text" value={saldos.saldoReserva} prefix={'$'} thousandSeparator={true} />)
				}
			}, width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "total"

		},
		{
			title: "Operador", field: "operador", width: "15%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Metodo Pago",
			field: "transaccionesMetodoPago",
			width: "15%",
			cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			hidden: parseInt(user.roll) != TAQUILLA,
		}
	];


	const actions = [
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEye} size="lg" /></div>,
			tooltip: "Ver",
			onClick: (event, rowData) => seleccionaRegistro(item, 'detalle'),

		})
	]

	return (
		<div>
			<Tabla columns={columns} data={reservas} actions={actions} title='Listado de Historial de Reservas' />
		</div>
	)
}

export default VerReservas