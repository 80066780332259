
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { MDBTable, MDBTableBody, MDBTableFoot, MDBTableHead } from 'mdbreact';
import Logo from '../../paginas/img/logo.png'

const InformesPrint = React.forwardRef((props, ref) => {

    const total = props.data && props.data.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total, 0
    );


    const cantidad = props.data && props.data.reduce(
        (accumulator, currentValue) => accumulator + currentValue.cantidad, 0
    );

    return (
        <div ref={ref} className=" pagebreak" >
            <h2><img src={Logo} className="logoPdf" /> Informe {props.titulo}</h2>
            <MDBTable small responsive>
                <tbody>
                    <tr className="tabla">
                        <th>Fecha servicio</th>
                        <th>Codigo reserva</th>
                        <th>Operador</th>
                        <th>Detalle servicio</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                    </tr>
                </tbody>
                <MDBTableBody>
                    {props.data && props.data.map((item, index) => {
                        return (
                            <tr key={index} className="tabla">
                                <td>{item.fecha}</td>
                                <td>{item.reserva}</td>
                                <td>{item.operador}</td>
                                <td>{item.detalleServicio}  </td>
                                <td>{item.cantidad}</td>
                                <td className='d-flex'> <NumericFormat displayType="text" value={item.total} prefix={'$'} thousandSeparator={true} /></td>
                            </tr>
                        )
                    })
                    }
                    <tr className='tabla'>
                        <td colSpan="3" ></td>
                        <td ><b>Total servicios</b></td>
                        <td ><b>{cantidad}</b></td>
                        <td className='d-flex'><b><NumericFormat displayType="text" value={total} prefix={'$'} thousandSeparator={true} /></b></td>
                    </tr>
                </MDBTableBody>

            </MDBTable>
        </div >

    )
})


export default InformesPrint;