import React, {useState, useEffect} from 'react'
import { MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput  } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit, faCheckCircle, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'


const Eliminar = ({nombre, select, setFuncion, peticionDelete}) => {

	return(
        <div>
            <h4>¿Desea Realmente Eliminar?</h4>
            <hr/>
            <div className="col text-center">

                <p>{nombre}: {select.nombre}</p>
                <button 
                    onClick={peticionDelete}
                    className="btn boton_estandar"							
                    >Eliminar
                </button>
                <button 
                        onClick={e=>setFuncion('ver')}
                        className="btn boton_estandar"							
                        >Cancelar
                </button>
                
            </div>
        </div>
	)
}

export default Eliminar