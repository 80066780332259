import { faCalendarDays, faCar, faCheck, faChevronRight, faCreditCardAlt, faHiking, faTicket, faUser, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import React from 'react'
import { NumericFormat } from 'react-number-format'

const arreglarFechaPedido = (fecha) => moment(fecha).format("YYYY-MM-DD h:mm:ss")

const InformacionReserva = ({ data, verServicios, verVisitantes, ingresar, calcularEdad, verificarDatosIcon }) => {
    const senderoMultiple = data?.tipoIngreso?.senderoMultiple === 'true';

    return (
        <div>
            <div className='informacionReserva'>
                <div className='col-md-2'>
                    <FontAwesomeIcon icon={faTicket} size="3x" style={{ marginTop: 20 }} />
                </div>
                <div className='col-md-10'>
                    <span>Estado: <b className={`estados ${data.estado}`}>{data.estado}</b></span>
                    <span className='estado-reservas'> <b className={`estados ${data.estadoReserva.background}`}>{data.estadoReserva.estado}</b></span>
                    <h2><b>{data.tipoIngreso.nombre}</b></h2>
                    {data.codigoReserva && <> <span>Reserva: <b>{data.codigoReserva}</b> - TIPO R: <b>OPERADORA</b></span><br></br></>}
                    <span>Fecha de realización de la Reserva: <b>{data.fechaReserva}</b></span>
                </div>
            </div>
            <div className='d-flex'>
                <div className='col-md-6 columaReserva'>
                    <h5><FontAwesomeIcon icon={faCreditCardAlt} size="1x" style={{ marginRight: 0 }} /> Datos de pago:</h5>
                    <hr></hr>
                    <span><b>Total Reserva:</b></span>
                    <h3><b><NumericFormat displayType="text" value={data.total} prefix={'$'} thousandSeparator={true} /></b></h3>
                    {senderoMultiple ?
                        <div>
                            <h5><FontAwesomeIcon icon={faHiking} size="1x" style={{ marginRight: 0 }} /> Senderos:</h5>
                            <hr></hr>
                            {data.senderosDias.map((sendero, index) => (
                                <div>
                                    <b><FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 0 }} /> Dia {index + 1}</b>
                                    <p><b>Fecha: </b>{arreglarFechaPedido(sendero.fecha)}</p>
                                    <p><b>Sendero: </b> {sendero.sendero.nombre}</p>
                                </div>
                            ))}

                            <hr></hr>
                        </div>
                        :
                        null}
                </div>

                <div className='col-md-6 columaReserva'>
                    <h5><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /> Visitantes:</h5>
                    <hr></hr>
                    {data.personas.map((persona, index) => {
                        if (index > 0) return null;
                        return (<div key={persona.id} >
                            <b><FontAwesomeIcon icon={faUser} size="1x" style={{ marginRight: 0 }} /> Visitante {index + 1}</b>
                            <p>{verificarDatosIcon(persona.nombre)} <b>Nombre Completo: </b>{persona.nombre} {persona.segundoNombre && persona.segundoNombre} {persona.primerApellido} {persona.segundoApellido}</p>
                            <p>{verificarDatosIcon(persona.numeroDocumento)} <b>Documento: </b>{persona.numeroDocumento}</p>
                            <p>{verificarDatosIcon(persona.tipoDocumento)} <b>Tipo Documento: </b>{persona.tipoDocumento}</p>
                        </div>)
                    }
                    )}

                </div>

            </div>
            <div className='informacion-reserva-btn'>
                <div className="btn  boton_estandar boton_estandar-icon" onClick={verVisitantes}>
                    <FontAwesomeIcon icon={faUsers} size="1x" className='btn-icon' />
                    <p>Ver Visitantes</p>
                </div>
                <div className="btn boton_estandar boton_estandar-icon" onClick={verServicios}>
                    <FontAwesomeIcon icon={faCar} size="1x" className='btn-icon' />
                    <p>Ver Servicios</p>
                </div>
            </div>
            <div className='informacion-reserva-btn' onClick={ingresar}>
                <div className="btn  boton_estandar-visitantes">
                    <p>Ingresar Visitantes</p>
                    <FontAwesomeIcon icon={faChevronRight} size="1x" className='btn-icon' />
                </div>
            </div>
        </div>
    )
}

export default InformacionReserva