import React, { useState, useEffect } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import axios from 'axios'
import moment from 'moment'
import { errores } from '../hooks/Funciones'

const EditarOperadores = ({ baseUrl, setFuncion, operadores, setOperadores, Titulo, registroSelect, setRegistroSelect, setTituloPag }) => {
	const [error, setError] = useState({ activo: false, mensaje: 'Hola' })
	const [imagenOld, setImagenOld] = useState(registroSelect.imagen)

	const peticionPut = async () => {

		let { id, nombre, representante, telefono, codigo, correo, direccion, ciudad, imagen, imagenNueva, nombreImaNueva, activo } = registroSelect

		if (imagenNueva !== undefined) {
			let formatosPermitidos = ['.png', '.jpeg', '.jpg', '.JPEG']
			let formato = typeof (imagenNueva) !== "string" ? imagenNueva.type.replace('image/', '.') : null;

			if (imagenNueva !== '' && imagenNueva.size < 2000000 && formatosPermitidos.includes(formato)) {

				let f = new FormData()
				f.append('id', id)
				f.append('nombre', nombre)
				f.append('representante', representante)
				f.append('telefono', telefono)
				f.append('codigo', codigo)
				f.append('correo', correo)
				f.append('direccion', direccion)
				f.append('ciudad', ciudad)
				f.append('imagen', imagenNueva)
				f.append('nombre_imagen', nombreImaNueva)
				f.append('nombre_imagen_vieja', imagenOld)
				f.append('estado', activo)
				f.append('METHOD', 'PUT')

				await axios.post(baseUrl, f)

					.then(response => {

						let dataNueva = operadores
						dataNueva.map(item => {
							if (item.id === id) {
								item.nombre = nombre
								item.representante = representante
								item.telefono = telefono
								item.codigo = codigo
								item.correo = correo
								item.direccion = direccion
								item.ciudad = ciudad
								item.imagePrev = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
								item.imagen = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
								item.activo = activo
							}
						})
						setOperadores(dataNueva)
						//console.log('enviado')
						errores('Limpio', setError)
					}).catch(error => {
						console.log('muestra erro: ' + error);
					})
				setFuncion('ver')
			}
			// console.log('nombreImagenFormat: '+fecha+NombreImagen+formato)
		} else if (nombre !== '' && representante !== '' && telefono !== '') {
			let f = new FormData()
			f.append('id', id)
			f.append('nombre', nombre)
			f.append('representante', representante)
			f.append('telefono', telefono)
			f.append('codigo', codigo)
			f.append('correo', correo)
			f.append('direccion', direccion)
			f.append('ciudad', ciudad)
			f.append('imagen', imagen)
			f.append('nombre_imagen', nombreImaNueva)
			f.append('estado', activo)
			f.append('METHOD', 'PUT')

			await axios.post(baseUrl, f)

				.then(response => {

					let dataNueva = operadores
					dataNueva.map(item => {
						if (item.id === id) {
							item.nombre = nombre
							item.representante = representante
							item.telefono = telefono
							item.codigo = codigo
							item.correo = correo
							item.direccion = direccion
							item.ciudad = ciudad
							item.imagePrev = imagen
							item.imagen = imagen
							item.activo = activo
						}
					})
					setOperadores(dataNueva)

					console.log('enviado')
					errores('Limpio', setError)
				}).catch(error => {
					console.log('muestra erro: ' + error);
				})
			setFuncion('ver')
		} else errores('CampoVacio', setError)

	}

	const recibeImagen = e => {

		let imagen = e.target.files[0]

		const fecha = moment(new Date()).format("YYYYMMDD-HHmmss");

		let formato = imagen.type.replace('image/', '.');
		let NombreImagen = imagen.name.replace(/[^A-Z0-9]+/ig, "_");
		let RutaServ = 'images/'
		console.log('nombreImagenFormat: ' + fecha + NombreImagen + formato)

		//let formatosPermitidos = ['.png','.jpeg','.jpg']

		setRegistroSelect({
			...registroSelect,
			imagePrev: URL.createObjectURL(imagen),
			imagenNueva: imagen,
			nombreImaNueva: RutaServ + fecha + NombreImagen + formato
		})
	}


	return (
		<div>

			<MDBContainer>
				<MDBRow>
					<MDBCol>
						<hr />
						{error.activo ?
							<span className="error"> Error: {error.mensaje}</span> : null
						}

						<div className="row">
							<div className="col-md-9">
								<div className='custom-control custom-switch '>
									<input type='checkbox' name="activo" className='custom-control-input form-control-lg mb-4' id='customSwitches'
										checked={String(registroSelect.activo) == "true" ? true : false}
										onChange={e => setRegistroSelect({ ...registroSelect, activo: e.target.checked })}
									/>
									<label className='custom-control-label' htmlFor='customSwitches'>Activar/Desactivar</label>
								</div>
								<MDBInput label="Nombre Operador" outline
									value={registroSelect.nombre}
									onChange={e => setRegistroSelect({ ...registroSelect, nombre: e.target.value })}
								/>

								<div className="input-group">
									<div className="input-group-prepend">
									</div>
									<div className="custom-file">
										<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
											name="imagen" accept="image/png, image/gif, image/jpeg" onChange={recibeImagen}
										/>
										<label className="custom-file-label">
											Seleccionar Logo (400px x 400px)
										</label>
									</div>
								</div>

								<div className='row'>
									<div className='col-md-6'>
										<MDBInput label="Rnt" outline
											value={registroSelect.codigo}
											onChange={e => setRegistroSelect({ ...registroSelect, codigo: e.target.value })}
										/>
									</div>
									<div className='col-md-6'>
										<MDBInput label="Telefono Operador" outline
											value={registroSelect.telefono}
											onChange={e => setRegistroSelect({ ...registroSelect, telefono: e.target.value })}
										/>
									</div>
								</div>

							</div>

							<div className="col-md-3">

								<div className="FotoPrev">

									<img src={
										registroSelect.imagePrev === registroSelect.imagen ?
											baseUrl + "/" + registroSelect.imagePrev
											: registroSelect.imagePrev
									} />
								</div>
							</div>

						</div>

						<div className="row sinMargen">
							<div className="col-md-6">
								<MDBInput label="Ciudad" outline
									value={registroSelect.ciudad}
									onChange={e => setRegistroSelect({ ...registroSelect, ciudad: e.target.value })}
								/>
							</div>
							<div className="col-md-6">
								<MDBInput label="Dirección" outline
									value={registroSelect.direccion}
									onChange={e => setRegistroSelect({ ...registroSelect, direccion: e.target.value })}
								/>
							</div>
							<div className="col-md-6">
								<MDBInput label="Correo Electrónico" outline
									value={registroSelect.correo}
									onChange={e => setRegistroSelect({ ...registroSelect, correo: e.target.value })}
								/>
							</div>
							<div className="col-md-6">
								<MDBInput label="Representante Legal" outline
									value={registroSelect.representante}
									onChange={e => setRegistroSelect({ ...registroSelect, representante: e.target.value })}
								/>
							</div>
						</div>





						<div className="text-center mt-4">
							<div className="col text-center">
								<button
									onClick={peticionPut}
									className="btn boton_estandar"
								>Editar Operador
								</button>
							</div>
						</div>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</div>
	)
}

export default EditarOperadores