import React from 'react'
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { NumericFormat } from 'react-number-format';

let fechasLuxon = {
	inicio: DateTime.now(),
	fin: DateTime.now()
}


/**
 * Retorna el número de días que han transcurrido desde la fecha inicial hasta la fecha final
 * {@link https://moment.github.io/luxon/#/math?id=diffs Luxon Math Diff}
 */
const getTotalDays = () => (
	fechasLuxon.fin.isValid ?
		Math.round(fechasLuxon.fin.diff(fechasLuxon.inicio).as('days')) :
		0
)

/**
 * Transforma una fecha tipo luxon en un string con el formato "Fri, Oct 14, 1983" traducido al español
 * @param {Object} date - Fecha tipo luxon
 * @returns {String} la fecha traducida según el formato dado
 * {@link https://moment.github.io/luxon/#/formatting?id=presets Luxon Formatting}
 */
const strFormatTranslateTime = (date) => (
	date.isValid ? date.setLocale('es-ES').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) : ""
);

/**
 * Valida si las fechas enviadas son la misma fecha dependiendo las horas que hayan pasado entre una y otra
 * @param {Object} fechas - Recibe las fechas de luxon enviadas por la funcion
 * @param {Object} services.inicio - La fecha tipo luxon inicial seleccionada
 * @param {Object} services.fin - La fecha tipo luxon final seleccionada
 * @returns {Boolean}
 */
const isSameDate = ({ inicio, fin }) => (
	fin.isValid &&
	fin.diff(inicio).as('hours') < 24
);

/**
 * Crea un nuevo componente de react para ser renderizado en el header de la tabla con las validaciones necesarias y los textos traduccidos
 * @param {Any} dates
 * @param {Array} dates.fechas - Array con las fechas seleccionadas desde el calendario
 * @returns {ReactNode} Un nodo html de react con la infomacion necesaria para el header de la tabla
 */
const ReservationHeaderDates = ({ fechas }) => {
	let textoFechas = ""
	fechasLuxon = {
		inicio: DateTime.fromJSDate(fechas[0]),
		fin: DateTime.fromJSDate(fechas[1])
	};
	textoFechas = ` Para el ${strFormatTranslateTime(fechasLuxon.inicio)}`;

	if (fechas.length > 1 && !isSameDate(fechasLuxon)) {
		let daysDiffObj = fechasLuxon.fin.isValid ? getTotalDays() : 1;
		textoFechas = ` Desde el ${strFormatTranslateTime(fechasLuxon.inicio)} al ${strFormatTranslateTime(fechasLuxon.fin)} (${daysDiffObj} dias)`;
	}

	return (
		<h3>
			<FontAwesomeIcon icon={faCalendarDays} color="white" size="1x" />
			<span style={{ fontSize: 15 }}>{textoFechas}</span>
		</h3>
	);
};

const TablaResumen = ({ registro, serviciosTotal, botonAtras, step }) => {
	const sumaTotal = (serviciosTotal.totalEntradas ? serviciosTotal.totalEntradas : 0) + (serviciosTotal.totalSeguros ? serviciosTotal.totalSeguros : 0) + (serviciosTotal.serviciosAlimentacion?.totalServicioAlimentacion ? serviciosTotal.serviciosAlimentacion?.totalServicioAlimentacion : 0) + (serviciosTotal.totalServicioGuia ? serviciosTotal.totalServicioGuia : 0) + (serviciosTotal.totalServicioVehiculo ? serviciosTotal.totalServicioVehiculo : 0) + (serviciosTotal.serviciosHospedaje?.totalServiciosGenericos ? serviciosTotal.serviciosHospedaje?.totalServiciosGenericos : 0)

	return (
		<div className='TablaResumen'>
			<div className='HeaderTablaResumen'>



				<div className='row'>
					<div className='col-md-7'>
						<h4 className='mb-1'>{serviciosTotal.tipoIngreso?.nombre}</h4>
					</div>

					{serviciosTotal.cuposDisponibles > 0 && <div className='col-md-5 text-right'>
						<h4 className='mb-1'>{serviciosTotal.cuposDisponibles} Cupos Disponibles</h4>
					</div>}
				</div>
				<div className='row'>
					{serviciosTotal.fechas && <div className='col-md-12'>
						<ReservationHeaderDates fechas={serviciosTotal.fechas} />
					</div>}
				</div>
			</div>
			<div className='CuerpoTablaResumen'>
				<div className='row'>
					<div className='col-md-4'>
						<table>
							<tbody>
								{serviciosTotal.tipoEntradas && serviciosTotal.tipoEntradas.map((tipoPersona, index) => {
									if (tipoPersona.contador > 0) return <tr key={index}><td>{tipoPersona.nombre}: </td><td>{tipoPersona?.contador}</td></tr>
								})}
							</tbody>
						</table>
					</div>
					<div className='col-md-8'>
						<table>
							<tbody>
								<tr><td>Entradas: </td><td className='precioTableResumen'>{serviciosTotal.entradas}</td></tr>
								<tr><td>Seguro Médico: </td><td className='precioTableResumen'>{serviciosTotal.seguroMedico}</td></tr>
								<tr>
									<td>Total Poliza: </td>
									<td className='precioTableResumen'>
										<NumericFormat displayType="text" value={serviciosTotal.totalSeguros} prefix={'$'} thousandSeparator={true} />
									</td>
								</tr>
								<tr>
									<td>Total Servicio Uton: </td>
									<td className='precioTableResumen'>
										<NumericFormat displayType="text" value={serviciosTotal.totalServicioGuia ? serviciosTotal.totalServicioGuia : 0} prefix={'$'} thousandSeparator={true} /></td>
								</tr>
								{serviciosTotal.totalServicioVehiculo ? <tr>
									<td>{serviciosTotal.tipoServicioVehiculo == 'Alquiler' ? 'Ruta Circular' : 'Ingreso Vehiculo'}: </td>
									<td className='precioTableResumen'>
										<NumericFormat displayType="text" value={serviciosTotal.totalServicioVehiculo ? serviciosTotal.totalServicioVehiculo : 0} prefix={'$'} thousandSeparator={true} /></td>
								</tr> : null}

								{serviciosTotal.serviciosHospedaje &&
									<tr>
										<td>Total Servicio Hospedaje: </td>
										<td className='precioTableResumen'>
											<NumericFormat displayType="text" value={serviciosTotal.serviciosHospedaje.totalServiciosGenericos} prefix={'$'} thousandSeparator={true} /></td>
									</tr>
								}
								{serviciosTotal.serviciosAlimentacion &&
									<tr>
										<td>Total Servicio Alimentación: </td>
										<td className='precioTableResumen'>
											<NumericFormat displayType="text" value={serviciosTotal.serviciosAlimentacion.totalServicioAlimentacion} prefix={'$'} thousandSeparator={true} /></td>
									</tr>
								}

								<tr>
									<td>Total Entradas: </td>
									<td className='precioTableResumen'>
										<NumericFormat displayType="text" value={serviciosTotal.totalEntradas} prefix={'$'} thousandSeparator={true} />				</td>
								</tr>
								<tr>
									<td>Total: </td>
									<td className='totalTableResumen'>
										<NumericFormat displayType="text" value={sumaTotal} prefix={'$'} thousandSeparator={true} />
									</td>
								</tr>

							</tbody>
						</table>
					</div>
					<div className='col-md-12'>
						<div>
							{step > 1 && <div className="col text-center">
								<button type="button" onClick={botonAtras} disabled={!serviciosTotal?.botonHabilitado} className="btn boton_estandar">Atrás </button>
							</div>}
							<div className="col text-center">
								<button disabled={!serviciosTotal?.botonHabilitado} type="submit" className="btn boton_estandar">Continuar </button>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default TablaResumen