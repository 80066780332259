import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes, faCalendarDays } from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

const Header = ({ titulo, funcion, setFuncion, Agregar, onClick, children, informes }) => (

    <div className="header-container ">
        <div className='header-container-principal'>
            <h2>{titulo}</h2>
            {funcion === 'ver' && !informes &&
                <>{children}</>
            }
            {funcion === 'editar' && informes &&
                <>{children}</>
            }
            {funcion === 'regresar' && informes &&
                <>{children}</>
            }
        </div>

        {titulo === 'Escritorio' ?

            <div>
                <a className='FechasFiltro '>
                    <FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 10, color: '#fff' }} />
                    <span style={{ color: '#fff' }}>27/12/2022</span>
                </a>
            </div>

            :
            <div className='derecha'>
                {(funcion === 'ver' || funcion == 'abonos') && Agregar ?

                    <div className=''>
                        <button
                            onClick={e => funcion === 'ver' ? setFuncion('crear') : funcion == 'abonos' ? setFuncion('crearAbonos') : null}
                            className="btn boton_estandar btn-lg ">
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                        {funcion !== 'ver' ?
                            <button
                                onClick={e => setFuncion('ver')}
                                className="btn boton_estandar btn-lg ">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            : null}
                    </div>
                    : null}

                {funcion === 'crear' || funcion === 'editar' || funcion === 'detalle' ?
                    <button
                        onClick={e => setFuncion('ver')}
                        className="btn boton_estandar btn-lg">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    : null}
                {funcion === 'regresar' ?
                    <button
                        onClick={onClick}
                        className="btn boton_estandar btn-lg "
                        type='button'>
                        <FontAwesomeIcon icon={faTimes} />

                    </button>
                    : null}
                {funcion === 'agregar-button' ?
                    <button
                        onClick={onClick}
                        className="btn boton_estandar btn-lg ">
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                    : null}
            </div>
        }
    </div>
)




export default Header