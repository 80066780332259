import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { uid } from "uid";


const ServicioAlimentacion = ({ titulo, servicios, serviciosTotal, actualizarServiciosGeneralesTotal = () => { }, readOnly = false, value, detalle }) => {
  const [serviciosAlimentacion, setServiciosAlimentacion] = useState([])
  const [serviciosAlimentacionCheck, setServiciosAlimentacionCheck] = useState()
  const desayunosIncluidosHospedaje = useRef(0)

  const [dias, setDias] = useState([])
  const costoTotal = useRef(0)

  const totalServicios = useRef({
    almuerzo: 0,
    desayuno: 0,
    cena: 0,
    vegetariano: 0,
    staff: 0,
    visitantes: 0,
    totalStaffVisitantes: 0
  })

  const staffRedimido = useRef({
    desayunoRedimir: 0,
    desayunoRedimido: 0,
    almuerzoRedimir: 0,
    almuerzoRedimido: 0,
    cenaRedimir: 0,
    cenaRedimido: 0
  })

  const serviciosArreglo = [{
    id: "1",
    nombre: "Vegetariano",
    checked: false,
    tarifa: 0
  }]

  servicios.map(servicio => {

    const item = {
      id: servicio.id,
      nombre: servicio.nombre,
      tarifa: parseInt(servicio.tarifa),
      checked: false
    }
    serviciosArreglo.push(item)
  })

  // Servicio vehiculo
  const totalCantidadServicioVehiculo = serviciosTotal?.servicioVehiculo
    ? serviciosTotal.servicioVehiculo.reduce((acc, obj) => {
      // Check if the vehicle has extradata and at least one object with a documento
      const hasDocumento = obj.extradata && obj.extradata.some(data => data.documento);

      // If hasDocumento is true, count the vehicle
      return acc + (hasDocumento ? 1 : 0);
    }, 0)
    : serviciosTotal?.servicioVehiculos ? serviciosTotal.servicioVehiculos.reduce((acc, obj) => {
      // Check if the vehicle has extradata and at least one object with a documento
      const hasDocumento = obj.extradata && obj.extradata.some(data => data.documento);

      // If hasDocumento is true, count the vehicle
      return acc + (hasDocumento ? 1 : 0);
    }, 0) : 0;

  const totalCantidadServicioGuia = serviciosTotal?.servicioGuia ? new Set(
    serviciosTotal?.servicioGuia
      .map(servicio => servicio.guia && servicio.guia.cedula) // Solo mapea si 'guia' existe
      .filter(cedula => cedula !== null && cedula !== undefined) // Filtra valores nulos y no definidos
  ).size
    : 0;


  const totalStaff = parseInt(serviciosTotal.entradas) + totalCantidadServicioVehiculo + totalCantidadServicioGuia;

  const procesarServicios = (servicio, totalServicios, staffRedimido, costoTotal) => {
    let necesitaActualizar = false;

    servicio.servicios.map(ser => {
      if (ser.checked) {
        necesitaActualizar = true;  // Establece que se necesita actualizar
        switch (ser.nombre) {
          case "Vegetariano":
            totalServicios.current.vegetariano = serviciosTotal.serviciosAlimentacion.contarServicios.vegetariano - 1;
            break;
          case "Almuerzo":
            totalServicios.current.almuerzo = serviciosTotal.serviciosAlimentacion.contarServicios.almuerzo - 1;

            const cantidadAlmuerzo = Math.trunc((totalServicios.current.almuerzo - staffRedimido.current.almuerzoRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));
            costoTotal.current -= ser.tarifa;

            if (cantidadAlmuerzo < staffRedimido.current.almuerzoRedimido) {
              staffRedimido.current.almuerzoRedimido -= 1;
              costoTotal.current += ser.tarifa;
            }
            break;
          case "Cena":
            totalServicios.current.cena = serviciosTotal.serviciosAlimentacion.contarServicios.cena - 1;

            const cantidadCena = Math.trunc((totalServicios.current.cena - staffRedimido.current.cenaRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));
            costoTotal.current -= ser.tarifa;

            if (cantidadCena < staffRedimido.current.cenaRedimido) {
              staffRedimido.current.cenaRedimido -= 1;
              costoTotal.current += ser.tarifa;
            }
            break;
          case "Desayuno":
            totalServicios.current.desayuno = serviciosTotal.serviciosAlimentacion.contarServicios.desayuno - 1;

            const cantidadDesayuno = Math.trunc((totalServicios.current.desayuno - staffRedimido.current.desayunoRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));
            costoTotal.current -= ser.tarifa;

            if (cantidadDesayuno < staffRedimido.current.desayunoRedimido) {
              staffRedimido.current.desayunoRedimido -= 1;
              costoTotal.current += ser.tarifa;
            }
            break;
          default:
            break;
        }
      }
    });

    return necesitaActualizar; // Retorna si se debe actualizar o no
  };

  const validarYProcesarAlimentacion = (servicio, totalServicios, staffRedimido, costoTotal, uniqueDataGuias, serviciosTotal) => {
    if (servicio.cedula) {
      const validaGuia = uniqueDataGuias.some(per => per?.guia?.cedula === servicio?.cedula);
      if (!validaGuia) {
        return procesarServicios(servicio, totalServicios, staffRedimido, costoTotal);
      }
    }

    if (servicio.numeroDocumento) {
      const validaPersona = serviciosTotal.personas.some(persona => persona.numeroDocumento === servicio.numeroDocumento);
      if (!validaPersona) {
        return procesarServicios(servicio, totalServicios, staffRedimido, costoTotal);
      }
    }

    if (servicio.documento) {
      const validarVehiculos = serviciosTotal.servicioVehiculos.some(vehiculo => vehiculo?.extradata?.some(persona => persona.documento === servicio.documento));
      if (!validarVehiculos) {
        return procesarServicios(servicio, totalServicios, staffRedimido, costoTotal);
      }
      console.log(servicio, 'servicio')
    }
    //  console.log(servicio.documento, 'servicio')

    return false;  // No necesita actualizar si no entra en los casos anteriores
  };

  useEffect(() => {
    if (value) {
      const servicioAlimentacionArray = value.serviciosAlimentacion
      const servicioAlimentacionFinal = []

      totalServicios.current = value.contarServicios;
      costoTotal.current = value.totalServicioAlimentacion;

      totalServicios.current.visitantes = serviciosTotal.entradas;
      totalServicios.current.staff = totalStaff;
      totalServicios.current.totalStaffVisitantes = serviciosTotal.entradas + totalStaff;

      serviciosTotal.servicioVehiculos && serviciosTotal.servicioVehiculos.map(camionetas => camionetas.extradata && camionetas.extradata.map((persona, index) => {
        const personaExiste = servicioAlimentacionArray.find(per => per.documento === persona.documento)
        if (!personaExiste) {
          serviciosTotal.serviciosAlimentacion?.dias?.map(dia => {
            let crearPersona = {
              ...persona,
              id: uid(15),
              nombre: `${persona.conductor ? persona.conductor : `Ruta Circular ${index + 1}`}(CONDUCTOR)`,
              dia: dia,
              staff: true,
              servicios: serviciosArreglo

            };
            if (!persona?.codReserva?.trim()) {
              servicioAlimentacionFinal.push(crearPersona)
            }
          })
        } else {
          const personaExisteMap = new Map();

          servicioAlimentacionArray.forEach(per => {
            const uniqueKey = `${per.documento}-${per.dia}`;

            if (per.documento === persona.documento && !personaExisteMap.has(uniqueKey)) {
              personaExisteMap.set(uniqueKey, per);
            }
          });

          servicioAlimentacionFinal.push(...personaExisteMap.values());
        }

      }))

      let seen = new Set();
      let uniqueDataGuias = serviciosTotal.servicioGuia && serviciosTotal.servicioGuia.filter(item => {
        let duplicate = seen.has(item.guia.cedula);
        seen.add(item.guia.cedula);
        return !duplicate;
      });


      uniqueDataGuias.map(persona => {
        const personaExiste = servicioAlimentacionArray.find(per => per.cedula === persona.guia.cedula)

        if (!personaExiste) {
          serviciosTotal.serviciosAlimentacion?.dias?.map(dia => {

            let crearPersona = {
              ...persona.guia,
              id: uid(15),
              nombre: `${persona.guia.nombre}(GUIA)`,
              dia: dia,
              staff: true,
              servicios: serviciosArreglo

            };
            if (!persona?.guia?.codReserva?.trim()) {
              servicioAlimentacionFinal.push(crearPersona)
            }
          })

        } else {

          const personaExisteMap = new Map();

          servicioAlimentacionArray.forEach(per => {
            const uniqueKey = `${per.cedula}-${per.dia}`;
            if (per.cedula === persona.guia.cedula && !personaExisteMap.has(uniqueKey) && persona.guia.cedula) {
              personaExisteMap.set(uniqueKey, per);
            }
          });

          servicioAlimentacionFinal.push(...personaExisteMap.values());

        }
      })

      serviciosTotal.personas && serviciosTotal.personas.map((persona, indx) => {
        const personaExiste = servicioAlimentacionArray.find(per => per.numeroDocumento === persona.numeroDocumento)
        if (!personaExiste) {
          serviciosTotal.serviciosAlimentacion?.dias?.map(dia => {
            let crearPersona = {
              ...persona,
              id: uid(15),
              dia: dia,
              servicios: serviciosArreglo
            };
            servicioAlimentacionFinal.push(crearPersona)
          })
        } else {
          const personaExisteMap = new Map();

          servicioAlimentacionArray.forEach(per => {
            const uniqueKey = `${per.numeroDocumento}-${per.dia}`;

            if (per.numeroDocumento === persona.numeroDocumento && !personaExisteMap.has(uniqueKey)) {
              personaExisteMap.set(uniqueKey, per);
            }
          });

          servicioAlimentacionFinal.push(...personaExisteMap.values());
        }
      })


      let actualizar = false
      const serviciosAlimentacionArray = serviciosTotal.serviciosAlimentacion.serviciosAlimentacion;

      serviciosAlimentacionArray.forEach(servicio => {
        const actualizarServicio = validarYProcesarAlimentacion(servicio, totalServicios, staffRedimido, costoTotal, uniqueDataGuias, serviciosTotal);
        if (actualizarServicio) {
          actualizar = true;  // Solo se establece a true si validarYProcesar lo indica
        }
      });

      if (actualizar) {
        actualizarServiciosGeneralesTotal({
          serviciosAlimentacion: servicioAlimentacionFinal, totalServicioAlimentacion: costoTotal.current, contarServicios: totalServicios.current, dias: value.dias, serviciosAlimentacionOptions: serviciosArreglo
        })
      }

      if (!value.dias) {
        const dias = {}
        serviciosTotal.serviciosAlimentacion.serviciosAlimentacion.map(alimentacion => {
          dias[alimentacion.dia] = alimentacion.dia
        })
        const diasActuales = Object.values(dias);
        setDias(diasActuales)

        const serviciosArreglo = [{
          id: "1",
          nombre: "Vegetariano",
          checked: false,
          tarifa: 0
        }]
        servicios.map(servicio => {

          const item = {
            id: servicio.id,
            nombre: servicio.nombre,
            tarifa: parseInt(servicio.tarifa),
            checked: false
          }
          serviciosArreglo.push(item)
        })

      } else {
        setDias(value.dias)
      }
      setServiciosAlimentacionCheck(servicioAlimentacionFinal)
      //  console.log(servicioAlimentacionFinal, 'servicioAlimentacionFinal')
      setServiciosAlimentacion(serviciosArreglo)
    } else {
      crearArrayPersonaDiasServicios()
    }

  }, [])

  const crearArrayPersonaDiasServicios = useCallback(() => {

    if (readOnly) {
      const dias = {}
      serviciosTotal.serviciosAlimentacion.serviciosAlimentacion.map(alimentacion => {
        dias[alimentacion.dia] = alimentacion.dia
      })
      const diasActuales = Object.values(dias);
      setDias(diasActuales)

      const serviciosArreglo = [{
        id: "1",
        nombre: "Vegetariano",
        checked: false,
        tarifa: 0
      }]

      servicios.map(servicio => {

        const item = {
          id: servicio.id,
          nombre: servicio.nombre,
          tarifa: parseInt(servicio.tarifa),
          checked: false
        }
        serviciosArreglo.push(item)
      })

      setServiciosAlimentacion(serviciosArreglo)
      setServiciosAlimentacionCheck(serviciosTotal.serviciosAlimentacion.serviciosAlimentacion)
      return null;
    }

    const serviciosArreglo = [{
      id: "1",
      nombre: "Vegetariano",
      checked: false,
      tarifa: 0
    }]

    servicios.map(servicio => {
      const item = {
        id: servicio.id,
        nombre: servicio.nombre,
        tarifa: parseInt(servicio.tarifa),
        checked: false
      }
      serviciosArreglo.push(item)
    })

    setServiciosAlimentacion(serviciosArreglo)

    const diaInicial = serviciosTotal.fechas[0];
    const diaFinal = serviciosTotal.fechas[1];

    const diferenciaDias = diaFinal ? Math.round((diaFinal - diaInicial) / (1000 * 60 * 60 * 24)) : 1;

    const dias = []
    const personasArray = []

    // Servicio vehiculo

    totalServicios.current.visitantes = serviciosTotal.entradas;
    totalServicios.current.staff = totalStaff;
    totalServicios.current.totalStaffVisitantes = serviciosTotal.entradas + totalStaff;

    for (let index = 1; index <= diferenciaDias; index++) {

      dias.push(index)
      serviciosTotal.personas && serviciosTotal.personas.map((persona, indx) => {
        let crearPersona = {
          ...persona,
          id: uid(15),
          dia: index,
          servicios: serviciosArreglo
        };

        personasArray.push(crearPersona)
      })

      serviciosTotal.servicioVehiculos && serviciosTotal.servicioVehiculos.map(camionetas => camionetas.extradata && camionetas.extradata.map((persona, indx) => {

        let crearPersona = {
          ...persona,
          id: uid(15),
          nombre: `${persona.conductor ? persona.conductor : `Ruta Circular ${indx + 1}`}(CONDUCTOR)`,
          dia: index,
          staff: true,
          servicios: serviciosArreglo

        };
        if (!persona?.codReserva?.trim()) {
          personasArray.push(crearPersona)
        }
      }))
      let seen = new Set();
      let uniqueDataGuias = serviciosTotal.servicioGuia && serviciosTotal.servicioGuia.filter(item => {
        let duplicate = seen.has(item.guia.cedula);
        seen.add(item.guia.cedula);
        return !duplicate;
      });

      uniqueDataGuias.map(persona => {
        let crearPersona = {
          ...persona.guia,
          id: uid(15),
          nombre: `${persona.guia.nombre}(GUIA)`,
          dia: index,
          staff: true,
          servicios: serviciosArreglo

        };
        if (!persona?.guia?.codReserva?.trim()) {
          personasArray.push(crearPersona)
        }
      })
    }

    setDias(dias)

    setServiciosAlimentacionCheck(personasArray)

  }, [servicios])

  const cambiarCheckInput = (id, idServicio, checked, nombreServicio) => {

    let array = []
    serviciosAlimentacionCheck.map(item => {
      if (item.id === id) {
        //nuevo array donde se va a remplazar el check seleccionado
        let arryser = []
        item.servicios.map(itemm => {
          if (itemm.id === idServicio) {
            arryser.push({ ...itemm, checked })

            if (checked) {
              if (nombreServicio == "Vegetariano") {
                totalServicios.current.vegetariano += 1
              }
              if (nombreServicio == "Almuerzo") {

                totalServicios.current.almuerzo += 1

                const cantidadAlmuerzo = Math.trunc((totalServicios.current.almuerzo - staffRedimido.current.almuerzoRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));

                if (item.staff && (staffRedimido.current.almuerzoRedimir > staffRedimido.current.almuerzoRedimido)) {

                  staffRedimido.current.almuerzoRedimido += 1
                } else {
                  costoTotal.current += itemm.tarifa
                  staffRedimido.current.almuerzoRedimir = cantidadAlmuerzo;
                }
              }

              if (nombreServicio == "Cena") {

                totalServicios.current.cena += 1
                const cantidadCena = Math.trunc((totalServicios.current.cena - staffRedimido.current.cenaRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));

                if (item.staff && (staffRedimido.current.cenaRedimir > staffRedimido.current.cenaRedimido)) {
                  staffRedimido.current.cenaRedimido += 1
                } else {
                  costoTotal.current += itemm.tarifa
                  staffRedimido.current.cenaRedimir = cantidadCena;
                }
              }

              if (nombreServicio == "Desayuno") {
                totalServicios.current.desayuno += 1

                const cantidadDesayuno = Math.trunc((totalServicios.current.desayuno - staffRedimido.current.desayunoRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));

                if (item.staff && (staffRedimido.current.desayunoRedimir > staffRedimido.current.desayunoRedimido)) {
                  staffRedimido.current.desayunoRedimido += 1
                } else {
                  costoTotal.current += itemm.tarifa
                  staffRedimido.current.desayunoRedimir = cantidadDesayuno;
                }
              }
            } else {
              /// quitar alimentacion 

              if (nombreServicio == "Vegetariano") {
                totalServicios.current.vegetariano -= 1
              }
              if (nombreServicio == "Almuerzo") {

                totalServicios.current.almuerzo -= 1

                const cantidadAlmuerzo = Math.trunc((totalServicios.current.almuerzo - staffRedimido.current.almuerzoRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));

                if (item.staff && (staffRedimido.current.almuerzoRedimido > cantidadAlmuerzo)) {
                  staffRedimido.current.almuerzoRedimido -= 1
                } else {
                  staffRedimido.current.almuerzoRedimir = cantidadAlmuerzo;

                  costoTotal.current -= itemm.tarifa

                  if (cantidadAlmuerzo < staffRedimido.current.almuerzoRedimido) {
                    staffRedimido.current.almuerzoRedimido -= 1
                    costoTotal.current += itemm.tarifa
                  }

                }
              }

              if (nombreServicio == "Cena") {
                totalServicios.current.cena -= 1

                const cantidadCena = Math.trunc((totalServicios.current.cena - staffRedimido.current.cenaRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));

                if (item.staff && (staffRedimido.current.cenaRedimido > cantidadCena)) {
                  staffRedimido.current.cenaRedimido -= 1
                } else {
                  staffRedimido.current.cenaRedimir = cantidadCena;

                  costoTotal.current -= itemm.tarifa

                  if (cantidadCena < staffRedimido.current.cenaRedimido) {
                    staffRedimido.current.cenaRedimido -= 1
                    costoTotal.current += itemm.tarifa
                  }

                }

              }

              if (nombreServicio == "Desayuno") {

                totalServicios.current.desayuno -= 1
                const cantidadDesayuno = Math.trunc((totalServicios.current.desayuno - staffRedimido.current.desayunoRedimido) / parseInt(process.env.REACT_APP_SECRET_ALIMENTACION_DESCUENTO));

                if (item.staff && (staffRedimido.current.desayunoRedimido > cantidadDesayuno)) {
                  staffRedimido.current.desayunoRedimido -= 1
                } else {
                  staffRedimido.current.desayunoRedimir = cantidadDesayuno;

                  costoTotal.current -= itemm.tarifa

                  if (cantidadDesayuno < staffRedimido.current.desayunoRedimido) {
                    staffRedimido.current.desayunoRedimido -= 1
                    costoTotal.current += itemm.tarifa
                  }
                }
              }
            }

          } else {
            arryser.push(itemm)
          }

        })
        //añade los mismos datos con un nuevo array de servicios
        array.push({
          ...item,
          servicios: arryser
        })

      } else {
        array.push(item)
      }
    })
    setServiciosAlimentacionCheck(array)
    actualizarServiciosGeneralesTotal({ serviciosAlimentacion: array, totalServicioAlimentacion: costoTotal.current, contarServicios: totalServicios.current, dias, serviciosAlimentacionOptions: serviciosAlimentacion })

  }
  //costoTotal.current = 0
  // console.log(serviciosAlimentacion, 'serviciosAlimentacion')
  //console.log(serviciosAlimentacionCheck, 'serviciosAlimentacionCheck')

  return (
    <div className='CampoServicios ' style={{ flexDirection: "column" }}>
      <h4 className='col-12  HeaderTablaServicios'>{titulo} {staffRedimido.current.almuerzoRedimir > 0 && `| Cortesía almuerzo(${staffRedimido.current.almuerzoRedimir})`} {staffRedimido.current.cenaRedimir > 0 && ` | Cortesía Cena(${staffRedimido.current.cenaRedimir})`} {staffRedimido.current.desayunoRedimir > 0 && ` | Cortesía Desayuno(${staffRedimido.current.desayunoRedimir})`} </h4>

      <div style={{ padding: 5, marginLeft: 10, marginRight: 10, marginTop: 3, marginBottom: 3, flex: 1 }}>
        {dias && dias.map(dia => {
          return (
            <div style={{ marginBottom: 15 }} key={dia}>
              <span style={{ fontSize: 18, fontWeight: 'bold' }}>DIA {dia}</span>
              <div style={{ display: 'flex', flex: 1, marginLeft: 0, marginRight: 10, marginTop: 3, marginBottom: 3, }}>
                <div style={{ flex: 6 }}>
                  <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: 14, fontWeight: 'bold' }}>VISITANTES:</span>
                  </div>
                </div>

                <div style={{ flex: 10, display: 'flex' }}>
                  {serviciosArreglo && serviciosArreglo.map(item => {
                    return (
                      <div key={item.nombre} style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'Uppercase' }}>
                        <span style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>{item.nombre}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
              {serviciosAlimentacionCheck && serviciosAlimentacionCheck.map(servi => {
                if (dia == servi.dia && !servi.staff) {
                  return (
                    <div key={servi.id} style={{ display: 'flex', flex: 1, backgroundColor: '#fff', padding: 5, marginRight: 10, marginTop: 3, marginBottom: 3, borderRadius: 10 }}>
                      <div style={{ flex: 6 }}>
                        <span>{servi.nombre}</span>    <span>{servi.primerApellido}</span>
                      </div>
                      <div style={{ flex: 10, display: 'flex' }}>
                        {servi.servicios && servi.servicios.map(servicios => <div style={{ flex: 10, display: 'flex' }} key={servicios.id}>
                          <div style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="custom-control custom-checkbox custom-control-inline" style={{ margin: 0 }}>
                                <input type="checkbox" id={`${servi.id}_${servicios.id}_${dia}`} checked={servicios.checked} className="custom-control-input" onChange={(e) => cambiarCheckInput(servi.id, servicios.id, e.target.checked, servicios.nombre)} disabled={readOnly} />
                                <label className="custom-control-label" htmlFor={`${servi.id}_${servicios.id}_${dia}`}></label>
                              </div>

                            </div>
                          </div>

                        </div>)}
                      </div>
                    </div>
                  )
                }
              })}

              <div style={{ display: 'flex', flex: 1, marginLeft: 0, marginRight: 10, marginTop: 6, marginBottom: 3, }}>
                <div style={{ flex: 6 }}> <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: 14, fontWeight: 'bold' }}>STAFF:</span>
                </div></div>

                <div style={{ flex: 10, display: 'flex' }}>
                  {serviciosArreglo && serviciosArreglo.map(item => {
                    return (
                      <div key={item.nombre} style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'Uppercase' }}>
                        <span style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>{item.nombre}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
              {serviciosAlimentacionCheck && serviciosAlimentacionCheck.map(servi => {
                if (dia == servi.dia && servi.staff) {
                  return (
                    <div key={servi.id} style={{ display: 'flex', flex: 1, backgroundColor: '#fff', padding: 5, marginRight: 10, marginTop: 3, marginBottom: 3, borderRadius: 10 }}>
                      <div style={{ flex: 6 }}>
                        <span>{servi.nombre}</span>
                      </div>
                      <div style={{ flex: 10, display: 'flex' }}>
                        {servi.servicios && servi.servicios.map(servicios => <div style={{ flex: 10, display: 'flex' }} key={servicios.id}>
                          <div style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="custom-control custom-checkbox custom-control-inline" style={{ margin: 0 }}>
                                <input type="checkbox" id={`${servi.id}_${servicios.id}_${dia}`} checked={servicios.checked} className="custom-control-input" onChange={(e) => cambiarCheckInput(servi.id, servicios.id, e.target.checked, servicios.nombre)} disabled={readOnly} />
                                <label className="custom-control-label" htmlFor={`${servi.id}_${servicios.id}_${dia}`}></label>
                              </div>

                            </div>
                          </div>

                        </div>)}

                      </div>
                    </div>
                  )
                }
              })}


            </div>
          )
          ///
        })

        }
        {value?.serviciosAlimentacion && (<div className="row">
          <div className='col-md-12 d-flex '>
            <span style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>OBSERVACIONES ALIMENTICIAS:</span>
          </div>
          <div className="col-12">
            <textarea id="story" style={{ border: 'none' }} name="story" defaultValue={value?.restriccionesAlimenticias} rows="5" cols="33" className="w-100" onBlur={(e) => actualizarServiciosGeneralesTotal({
              ...value,
              restriccionesAlimenticias: e.target.value
            })}>
            </textarea>
          </div>
        </div>)}

        {
          readOnly && <>

            <div style={{ marginBottom: 15 }} >
              <span style={{ fontSize: 18, fontWeight: 'bold' }}>TOTALES</span>
              <div style={{ display: 'flex', flex: 1, marginLeft: 0, marginRight: 10, marginTop: 3, marginBottom: 3, }}>
                <div style={{ flex: 6 }}> <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: 14, fontWeight: 'bold' }}>VISITANTES Y STAFF:</span>
                </div></div>

                <div style={{ flex: 10, display: 'flex' }}>
                  {serviciosArreglo.map(item => {
                    return (
                      <div key={item.nombre} style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'Uppercase' }}>
                        <span style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>{item.nombre}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flex: 1, backgroundColor: '#fff', padding: 5, marginRight: 10, marginTop: 3, marginBottom: 3, borderRadius: 10 }}>
              <div style={{ flex: 6 }}>
                <span>{totalStaff}</span>
              </div>
              <div style={{ flex: 10, display: 'flex' }}>

                <div style={{ flex: 10, display: 'flex' }} >
                  <div style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="d-flex justify-content-center align-items-center">
                      <span>{serviciosTotal.serviciosAlimentacion.contarServicios.vegetariano}</span>
                    </div>
                  </div>
                </div>

                <div style={{ flex: 10, display: 'flex' }} >
                  <div style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="d-flex justify-content-center align-items-center">
                      <span>{serviciosTotal.serviciosAlimentacion.contarServicios.almuerzo}</span>
                    </div>
                  </div>
                </div>

                <div style={{ flex: 10, display: 'flex' }} >
                  <div style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="d-flex justify-content-center align-items-center">
                      <span>{serviciosTotal.serviciosAlimentacion.contarServicios.cena}</span>
                    </div>
                  </div>
                </div>

                <div style={{ flex: 10, display: 'flex' }} >
                  <div style={{ flex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="d-flex justify-content-center align-items-center">
                      <span>{serviciosTotal.serviciosAlimentacion.contarServicios.desayuno}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <p className='mb-2'><b>Total: </b><NumericFormat displayType="text" value={serviciosTotal.serviciosAlimentacion.totalServicioAlimentacion} prefix={'$'} thousandSeparator={true} /> </p>
            <div className="row">
              <div className='col-md-12 d-flex '>
                <span style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>OBSERVACIONES ALIMENTICIAS:</span>
              </div>
              <div className="col-12">
                <textarea id="story" style={{ border: 'none' }} readOnly={detalle} name="story" defaultValue={serviciosTotal?.serviciosAlimentacion?.restriccionesAlimenticias} rows="5" cols="33" className="w-100" onBlur={(e) => actualizarServiciosGeneralesTotal({
                  ...serviciosTotal,
                  serviciosAlimentacion: {
                    ...serviciosTotal.serviciosAlimentacion,
                    restriccionesAlimenticias: e.target.value
                  }
                })}>
                </textarea>
              </div>
            </div>
          </>
        }


      </div >
      <br></br>
    </div >
  )
}

export default ServicioAlimentacion;