import React, { useState, useEffect, useContext } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'

import Header from '../componentes/Header';
import { useNavigate } from 'react-router-dom';
import * as RUTAS from '../../constantes/rutas';
import { ApiPaths } from '../../utils.js';
import { Form } from 'react-bootstrap';

const SubirUsuario = (a) => {
	//errores
	const [error, setError] = useState({ activo: false })
	const [registro, setRegistro] = useState({ activo: true })

	const navigate = useNavigate()

	const peticionPost = async (e) => {
		e.preventDefault();
		try {
			const { activo, nombre, tipo } = registro
			const f = new FormData()
			f.append('activo', activo.toString())
			f.append('nombre', nombre)
			f.append('tipo', tipo)
			f.append('METHOD', 'POST')
			await axios.post(ApiPaths.metodosPago, f)
			navigate(RUTAS.ADMIN_METODO_PAGO)

		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div>
			<Header titulo='Subir Metodo de Pago' funcion='regresar' onClick={() => navigate(RUTAS.ADMIN_METODO_PAGO)} Pagina={'Metodo de Pago'} Agregar={true} />
			<div className="row">
				<div className="col-md-12">
					<Form onSubmit={peticionPost}>
						<div className="row">
							<div className="col-md-12">
								{error.activo ?
									<span className="error"> Error: {error.mensaje}</span> : null
								}
								<div><hr />
									<div className='custom-control custom-switch '>
										<input type='checkbox' name="activo" className='custom-control-input form-control-lg mb-4' id='customSwitches'
											checked={String(registro.activo) == "true" ? true : false}
											onChange={e => setRegistro({ ...registro, activo: e.target.checked })}
											readOnly
										/>
										<label className='custom-control-label' htmlFor='customSwitches'>Activar/Desactivar</label>
									</div>

									<MDBInput name="nombre"
										required
										className={error.nombre ? "form-control is-invalid" : null} outline
										label={'Nombre'}
										value={registro.nombre}

										onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
									/>

									<div className='mb-4'>
										<select
											className={error.tipo ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"}
											name="tipo"
											required
											value={registro.tipo}
											onChange={e => setRegistro({ ...registro, tipo: e.target.value })}>
											<option value={""}>Selecciona un tipo</option>
											<option value="Efectivo">Efectivo</option>
											<option value="Tarjeta Credito">Tarjeta Credito</option>
											<option value="PSE">PSE</option>
										</select>

									</div>

									<br />
									<div className="col text-center">
										<button type='submit'
											className="btn boton_estandar"
										>{`Subir Metodo de Pago`}
										</button>
									</div>
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div >
		</div >
	)
}

export default SubirUsuario