import React from 'react'
import moment from 'moment'

const BusquedaFechas = ({ startDate, setStartDate, finalFecha, setFinalFecha }) => (
    <>
        <div>
            <label htmlFor="start">Inicial:</label>
            <input
                className='btn boton_estandar'
                type="date"
                value={moment(startDate).format("YYYY-MM-DD")}
                onChange={e => setStartDate(e.target.value)}
            />
        </div>
        <div>
            <label htmlFor="start">Final:</label>
            <input
                className='btn boton_estandar'
                type="date"
                value={moment(finalFecha).format("YYYY-MM-DD")}
                onChange={e => setFinalFecha(e.target.value)}
            />
        </div>
    </>
)




export default BusquedaFechas