import React from 'react'
import { MDBTable, MDBTableBody} from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import {TraeNombre} from '../hooks/Funciones'

const VerTiposIngreso = ({data, seleccionaRegistro}) => {

	return(
				<MDBTable hover>
				  <HeaderTabla array={['Id','Nombre','Tipo','Categoria','Capacidad Máx','Capacidad Actual','']} />
			      <MDBTableBody>

			      	{data.map((item, index) => {
			      		return(
					        <tr key={index} className="tabla_productos">
					          <td>{item.id}</td>
							  <td>{item.nombre}</td>
					          <td>{item.tipo}</td>
							  <td>{TraeNombre(data, item.padre)}</td>
							  <td>{item.capacidadlimite}</td>
							  <td>{item.capacidadactual}</td>
                              <td className="tabla_funciones">
							  	<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'}/>
					          </td>
					        </tr>
				        )
			        })}

			      </MDBTableBody>
			    </MDBTable>
	)
}

export default VerTiposIngreso