import React, { useState, useEffect, useContext } from 'react'
import VerVisitantes from './VerVisitantes'
import Header from '../componentes/Header';

import { ApiPaths } from '../../utils';
import BusquedaFechas from '../componentes/BusquedaFechas';
import moment from 'moment';
import axios from 'axios'
import UserContext from '../../context/UserContext'
import { SUPERADMIN } from '../../constantes/roles';
const VisitantesAdmin = () => {

	const [tituloPag, setTituloPag] = useState("Visitantes")
	const [funcion, setFuncion] = useState('ver')
	const [startDate, setStartDate] = useState(new Date());
	const [finalFecha, setFinalFecha] = useState(new Date());
	const [visitantes, setVisitantes] = useState([])

	const { user } = useContext(UserContext);

	useEffect(() => {
		peticionTraeDatos()
	}, [startDate, finalFecha])

	const peticionTraeDatos = async () => {
		try {

			if (parseInt(user.roll) != SUPERADMIN) {
				const resp = await axios.get(ApiPaths.visitantes, { params: { idUsuario: user.id_operador, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD") } })
				return setVisitantes(resp.data)

			}

			const resp = await axios.get(ApiPaths.visitantes, { params: { startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD") } })

			setVisitantes(resp.data)
		} catch (error) {
			setVisitantes([])
			return error
		}

	}

	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'}  >
				<BusquedaFechas
					startDate={startDate}
					setStartDate={setStartDate}
					finalFecha={finalFecha}
					setFinalFecha={setFinalFecha}
				/>
			</Header>
			<VerVisitantes
				data={visitantes}
				baseUrl={ApiPaths.visitantes}
			/>
		</div>
	)
}

export default VisitantesAdmin