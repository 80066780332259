import React from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used
import { TraeNombre } from '../hooks/Funciones'
import { NumericFormat } from 'react-number-format';
import Tabla from '../componentes/Tabla';

const VerTransacciones = ({ data, seleccionaRegistro, baseUrl }) => {

	const DevuelveIcon = (estado) => {
		let icon = ''
		if (estado === 'aprobado') { icon = <FontAwesomeIcon className={"iconTransaccApro"} icon={faCircleCheck} /> }
		else if (estado === 'fallido') { icon = <FontAwesomeIcon className={"iconTransaccFall"} icon={faCircleXmark} /> }
		return icon
	}

	const Estados = (dato) => {
		if (dato === 'PENDIENTE') {
			return 'P'
		} else if (dato === 'APROBADO') {
			return 'A'
		} else if (dato === 'EXPIRADO') {
			return 'E'
		} else if (dato === 'CANCELADO') {
			return 'C'
		} else if (dato === 'COMPLETADO') {
			return 'C'
		} else {
			return dato
		}
	}

	const columns = [
		{
			title: "Estado", field: "estado", render: (item) => <> <div className='tickets-estados'>
				<span className={`estados ${item.estado}`}>{Estados(item.estado)}</span>
				<div className={item.visto === 'false' ? 'tickets-estados-button' : null}>
				</div>
			</div>
			</>,
			width: "0%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: "Id", field: "id", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			defaultSort: "desc"
		}, {
			title: "Fecha", field: "fecha", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: "Cod. Reserva", field: "codigoReserva", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: "Método Pago", field: "metodoPago", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: "Total", render: (item) => <NumericFormat displayType="text" value={item.total} prefix={'$'} thousandSeparator={true} />, width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "total"

		},
		{
			title: "Usuario", field: "usuario", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: "Operador", field: "operadora", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
	];

	return (
		<div className="row">
			<div className="col-md-12">
				<Tabla columns={columns} data={data} title='Listado de Transacciones' />
			</div>
		</div>
	)
}

export default VerTransacciones