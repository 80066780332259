import React, { useEffect, useState } from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import HeaderTabla from '../componentes/HeaderTabla'
import BotonesFormulario from '../componentes/BotonesFormulario'
import axios from 'axios'
import { ApiPaths } from '../../utils.js';
import Header from '../componentes/Header';
import { useNavigate } from 'react-router-dom';
import * as RUTAS from '../../constantes/rutas';
import Eliminar from '../componentes/Eliminar';

const VerMetodosPago = () => {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [funcion, setFuncion] = useState('ver')
	const [registroSelect, setRegistroSelect] = useState()

	useEffect(() => { peticionTraeDatos() }, [])

	const peticionTraeDatos = async () => {
		try {
			const response = await axios.get(ApiPaths.metodosPago)
			setData(response.data)
		} catch (error) {
			console.log(error)
		}

	}

	const Seleccionar = async (objeto, funcion) => {
		try {
			if (funcion === "editar") {
				navigate(`${RUTAS.ADMIN_METODO_PAGO}/editar/${objeto.id}`, { state: objeto })
			}
			if (funcion === "eliminar") {
				setFuncion(funcion)
				setRegistroSelect(objeto)
			}
		} catch (error) {
			console.log(error)
		}
	}


	const peticionDeletee = async () => {
		try {
			let f = new FormData();
			f.append("METHOD", "DELETE");
			await axios.post(ApiPaths.metodosPago, f, { params: { id: registroSelect.id } })
			peticionTraeDatos()
			setFuncion('ver')
		} catch (error) {
			console.log(error)
		}
	}
	return (
		<div>
			<Header titulo='Ver Metodos de Pago' funcion={funcion} setFuncion={() => navigate(RUTAS.ADMIN_METODO_PAGO_CREAR)} Pagina={'Metodos de Pago'} Agregar={true} />

			{funcion === 'ver' ?
				<div className="row">
					<div className="col-md-12">

						<MDBTable hover>
							<HeaderTabla array={['', 'Activo', 'Nombre', 'Tipo', '']} />
							<MDBTableBody>
								{data && data.map((item, index) => {
									return (
										<tr key={index}>
											<td><div className={String(item.activo) == "true" ? "bola_estado_activo" : "bola_estado_desact"}></div></td>
											<td>{item.activo === 'true' ? 'Si' : 'No'}</td>
											<td>{item.nombre}</td>
											<td>{item.tipo}</td>
											<td className="tabla_funciones">
												<BotonesFormulario SeleccionarCategoria={Seleccionar} item={item} Roll={'Admin'} />
											</td>
										</tr>
									)
								})}
							</MDBTableBody>
						</MDBTable>
					</div>
				</div>
				: null
			}

			{funcion === 'eliminar' ?
				<Eliminar
					nombre={'Metodos de pago'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee} />

				: null
			}


		</div>
	)
}

export default VerMetodosPago