import React, { useState, useEffect } from 'react'
import { MDBInput } from 'mdbreact';
import { Form } from 'react-bootstrap'
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro } from '../hooks/Funciones'
import { errores } from '../hooks/Funciones'

const SubirTipoIngreso = ({ baseUrl, data, setData, setFuncion, }) => {

	//errores
	const [error, setError] = useState({ activo: false })
	const [registro, setRegistro] = useState({ activo: true, multipleDias: false })

	const [agotado, setAgotado] = useState({ nombre: 'Sin Cupo', rangofin: 0 })
	const [pocasExis, setPocasExis] = useState({ nombre: 'Poco Cupo', rangoini: 0, rangofin: 0 })
	const [conExist, setconExist] = useState({ nombre: 'Con Disponibilidad', rangoini: 0 })

	//Consulta para subir o crear los datos 
	const peticionPost = async () => {

		let { nombre, tipo, padre, capacidadlimite, descripcion, multipleDias, restriccionDias } = registro
		let AlertaFull = [agotado, pocasExis, conExist]
		if (!existeRegistro(data, nombre)) {
			if (nombre !== undefined && tipo !== undefined && padre !== undefined && capacidadlimite !== undefined && descripcion !== undefined && restriccionDias !== undefined) {
				let f = new FormData()
				f.append('nombre', nombre)
				f.append('tipo', tipo)
				f.append('padre', traeIdNombre(data, padre))
				f.append('capacidadlimite', capacidadlimite)
				f.append('capacidadactual', 0)
				f.append('descripcion', descripcion)
				f.append('multipleDias', multipleDias)
				f.append('restriccionDias', restriccionDias)
				f.append('alertas', JSON.stringify(AlertaFull))
				f.append('METHOD', 'POST')
				await axios.post(baseUrl, f)

					.then(response => {
						setData(data.concat(response.data))
						setRegistro({ activo: false, multipleDias: false })
						errores('Limpio', setError)
						setFuncion('ver')
					}).catch(error => {
						console.log(error);
					})
			} else errores('CampoVacio', setError)
		} else errores('Existe', setError)

	}


	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />

						<MDBInput
							label="Nombre del Ingreso" name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
							value={registro.nombre}
							onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
						/>

						<div className='row'>
							<div className='col-md-6'>
								<select className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="sede"
									value={registro.tipo}
									onChange={e => setRegistro({ ...registro, tipo: e.target.value })}>
									<option value={""}>Selecciona una opción</option>
									<option value={"Ingreso"}>Ingreso</option>
									<option value={"Sendero"}>Sendero</option>
								</select>
							</div>

							<br></br>

							<div className='col-md-6'>
								<select className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="sede"
									value={registro.padre}
									onChange={e => setRegistro({ ...registro, padre: e.target.value })}>
									<option value={""}>Selecciona un Ingreso</option>
									{data.map((item, index) => {
										if (item.tipo === "Ingreso") {
											return <option key={index} value={item.nombre}>{item.nombre}</option>
										}
									})}
									<option value={"Ninguno"}>Ninguno</option>
								</select>
							</div>
						</div>
						<div className='row'>
							<div className='col-md-4'>
								<MDBInput name="usuario" required className={error.campoUsuario ? "form-control is-invalid" : null} outline
									label={'Capacidad Máxima'}
									value={registro.capacidadlimite}
									onChange={e => setRegistro({ ...registro, capacidadlimite: e.target.value })}
									type='number'
								/>
							</div>
							<div className='col-md-4 d-flex align-items-center' >
								<MDBInput
									name="restriccionDias"
									required
									outline
									label={'Restricción de dias calendario'}
									value={registro.restriccionDias}
									type='number'
									onChange={e => setRegistro({ ...registro, restriccionDias: e.target.value })}
								/>
							</div>
							<div className='col-md-4 d-flex align-items-center' >
								<Form.Check
									type='checkbox'
									id={`default-checkbox`}
									label={`Multiple dias`}
									checked={registro.multipleDias}
									onChange={e => setRegistro({ ...registro, multipleDias: e.target.checked })}
								/>
							</div>
						</div>
						<MDBInput label="Descripción" name="descripcion" required outline
							value={registro.descripcion}
							onChange={e => setRegistro({ ...registro, descripcion: e.target.value })}
						/>

						<h5>Alertas:</h5>
						<div className="row">

							<div className="col-md-4"> <br></br><p className="estados Rojo">Sin Cupo</p></div>
							<div className="col-md-8"><MDBInput label="Rango Menor a " outline
								value={agotado.rangofin}
								onChange={e => setAgotado({ ...agotado, rangofin: e.target.value })} />
							</div>


							<div className="col-md-4"> <br></br><p className="estados Amarillo">Con pocos Cupos</p></div>
							<div className="col-md-4"><MDBInput label="Rango Inicial " outline
								value={pocasExis.rangoini}
								onChange={e => setPocasExis({ ...pocasExis, rangoini: e.target.value })} />
							</div>
							<div className="col-md-4"><MDBInput label="Rango Final" outline
								value={pocasExis.rangofin}
								onChange={e => setPocasExis({ ...pocasExis, rangofin: e.target.value })} />
							</div>


							<div className="col-md-4"> <br></br><p className="estados Verde">Con Disponibilidad</p></div>
							<div className="col-md-8"><MDBInput label="Rango Mayor a" outline
								value={conExist.rangoini}
								onChange={e => setconExist({ ...conExist, rangoini: e.target.value })} />
							</div>


						</div>

						<br />
						<div className="col text-center">
							<button
								onClick={peticionPost}
								className="btn boton_estandar"
							>{`Subir Tipo de Ingreso`}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirTipoIngreso