import React from 'react'
import Tabla from '../componentes/Tabla';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faEdit, faTicket, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const VerOperadores = ({ data, urlOperadores, seleccionaRegistro }) => {
	const columns = [
		{
			title: '',
			render: (item) => <div className={String(item.activo) == "true" ? "bola_estado_activo" : "bola_estado_desact"}></div>,
			field: "", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: 'Logo',
			render: (item) => <div className="ImgProducto"><img src={`${urlOperadores}${item.imagen}`} /></div>,
			field: "logo",

		},
		{
			title: "Operador", field: "nombre"
		},
		{
			title: "Representante", field: "representante"
		},
		{
			title: "Cod", field: "codigo"
		},
		{
			title: "Ciudad", field: "ciudad"
		},

	];
	const actions = [
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faDollarSign} size="lg" /></div>,
			tooltip: "Abonos",
			onClick: (event, rowData) => seleccionaRegistro(item, 'abonos'),
		}),
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEdit} size="lg" /></div>,
			tooltip: "Editar",
			onClick: (event, rowData) => seleccionaRegistro(item, 'editar'),

		}),
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faTrashAlt} size="lg" /></div>,
			tooltip: "Eliminar",
			onClick: (event, rowData) => seleccionaRegistro(item, 'eliminar'),
		}),]
	return (
		<Tabla columns={columns} data={data} actions={actions} title='Listado de Operadores' />

	)
}

export default VerOperadores