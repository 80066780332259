import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { NumericFormat } from 'react-number-format';

import { METHODS_TYPES } from "../../../utils";

const ContadorVisitantes = ({ tarifas, setTarifas, condicion, active, setActive, totales, disabled, errors, total = 0 }) => {
    const [totalNacional, setTotalNacional] = useState(total)
    const [cantidadNacional, setCantidadNacional] = useState(0)

    const SumayResta = (id, operacion) => {

        let selectedTarifa = tarifas.find(tarifa => tarifa.id === id);
        let idxItem = tarifas.findIndex(tarifa => tarifa.id === id);
        let contador = selectedTarifa.hasOwnProperty('contador') ? selectedTarifa.contador : 0;

        if (operacion === METHODS_TYPES.adicionar) {
            contador += 1;
        } else if (METHODS_TYPES.remover) {
            if (contador <= 0) return
            contador -= 1;
        }
        tarifas.splice(idxItem, 1, { ...selectedTarifa, contador })

        setTarifas(Array.from(tarifas));
        formatDataUpdated(tarifas);
    }

    const formatDataUpdated = (tarifasCounterArr) => {
        totales({
            total: SumaTotales(tarifasCounterArr),
            contador: CuentaCuantosNacional(tarifasCounterArr),
            data: tarifas
        })
    }

    const SumaTotales = (tarifasCounterArr) => {
        let resultado = tarifasCounterArr.reduce((acumulado, actual) => {
            let contador = actual.hasOwnProperty('contador') ? actual.contador : 0;
            return (acumulado + (contador * actual.tarifa))
        }, 0);
        setTotalNacional(resultado);
        return resultado;
    }

    const CuentaCuantosNacional = (tarifasCounterArr) => {
        let resultado = tarifasCounterArr.reduce((acumulado, actual) => {
            let contador = actual.hasOwnProperty('contador') ? actual.contador : 0;
            return (acumulado + contador)
        }, 0);
        setCantidadNacional(resultado);
        return resultado;
    }

    useEffect(() => {
        formatDataUpdated(tarifas)
    }, [tarifas])

    return (
        <div className={`${active ? 'ContadorResumen transformar' : 'ContadorResumen'} ${errors.tiposEntradas && 'is-invalid'} `}>
            <div>
            </div>
            <button type="button" onClick={() => setActive(!active)} className='HeaderContadorResumen'>
                <h6>Visitantes {condicion} <FontAwesomeIcon icon={faSortDown} color="white" size="1x" style={{ marginTop: 0 }} /></h6>
            </button>

            {!active ?
                <div>
                    {tarifas.map((item, index) => {
                        if (String(item.nacionalidad).toLowerCase() === String(condicion).toLowerCase()) {
                            return (
                                <div key={index}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ flex: 6, paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>
                                            <p><b>{item.nombre}</b></p>
                                            <p style={{ color: '#aaa', fontSize: 13 }}>De {item.edadinicial} a {item.edadfinal} años: </p>
                                            <p>
                                                <b style={{ color: '#aaa', fontSize: 15 }}>
                                                    <span className='TextAzul'>tarifa: <NumericFormat displayType="text" value={item.tarifa} prefix={'$'} thousandSeparator={true} /></span>
                                                </b>
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', flex: 5 }}>
                                            <div style={{ flex: 1, justifyContent: 'center' }}>
                                                <input onClick={e => SumayResta(item.id, METHODS_TYPES.remover)} className='botonContadorResumen' type="button" value="-" />
                                            </div>
                                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <span className='TextAzul'><b>{item.contador || 0}</b></span>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <input disabled={disabled} type="button" onClick={e => SumayResta(item.id, METHODS_TYPES.adicionar)} className='botonContadorResumen' value="+" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>
                            )
                        }
                    })}
                    <div className='col-md-12'>
                        <h6 style={{ textAlign: 'center', marginTop: 10 }}><b>Total:</b></h6>

                        <h4 className='TextAzul' style={{ textAlign: 'center', marginBottom: 15 }}><b>
                            <NumericFormat displayType="text" value={totalNacional.toString()} prefix={'$'} thousandSeparator={true} />
                        </b></h4>
                    </div>
                </div>
                :
                <div className='col-md-12'>
                    <p style={{ marginTop: 7, textAlign: 'center' }}>{cantidadNacional} visitantes {condicion}</p>
                    <h6 style={{ textAlign: 'center' }}>
                        <b>Total: </b>
                        <b className='TextAzul' style={{ fontSize: 20 }}>
                            <NumericFormat displayType="text" value={totalNacional.toString()} prefix={'$'} thousandSeparator={true} />
                        </b>
                    </h6>

                </div>
            }

        </div>
    )
}

export default ContadorVisitantes