import React from 'react'
import './index.css'
const index = ({ data, stepActual }) => (
    <div className="stepper-wrapper">
        {data && data.map((step) =>
            <div className={`stepper-item ${step.step < stepActual && 'completed'} ${step.step === stepActual && 'active'}`}>
                <div className="step-counter">{step.step}</div>
                <div className="step-name">{step.nombre}</div>
            </div>
        )}
    </div>

)


export default index