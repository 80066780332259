import React from 'react'
import { verificarRoles } from '../../constantes/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFloppyDisk, faRefresh, faCreditCardAlt, faUsers, faUser, faBinoculars, faVanShuttle, faUtensils, faTaxi} from '@fortawesome/free-solid-svg-icons'
import { ApiPaths } from '../../utils';

const HeaderUserPrin = ({ Logo, Avatar, nombre, Roll, operador, imagenOperador }) => {

    const handleClearCache = () => {
		if ('caches' in window) {
		// Borrar todas las caches
		caches.keys().then((names) => {
			names.forEach((name) => {
			caches.delete(name);
			});
		});
		}
		// Recargar la página forzando la recarga desde el servidor
		window.location.reload(true);
	};


    return(
        <div>
            <div className="contenedor linea">
                <img src={Logo} className="logo" />
            </div>

            <div style={{display: 'flex', padding: 10, flexWrap: 'wrap' }}>
                <div style={{flex: 1}}>
                    <img src={imagenOperador?  `${ApiPaths.operadores}${imagenOperador}` : Avatar} className="avatar" />
                </div>
                <div style={{flex: 9}} className="cont_datos_usuario">
                    <h5>{nombre}</h5>
                    <p>{verificarRoles(parseInt(Roll))}</p>
                    <p>Operador:{operador}</p>
                </div>
                <div style={{flex: 1}}>
                    <button 
                        onClick={handleClearCache}
                        className="btn boton_estandar_blanco"							
                        > <FontAwesomeIcon icon={faRefresh} size="lg"/>
                    </button>
                </div>
            </div>
        </div>


    )
}


export default HeaderUserPrin