import React, { useContext, useState } from 'react'
import moment from 'moment'
import { Form, Nav, Tab } from 'react-bootstrap'
import InputAutocomplete from './InputAutocomplete'
import axios from 'axios'
import { ApiPaths, TIPO_SERVICIOS } from '../../../utils';
import InformacionReserva from './InformacionReserva';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import InformacionVisitantes from './InformacionVisitantes'
import InformacionServicios from './InformacionServicios'
import { toast } from 'react-toastify'

import CrearCompraPos from './CrearCompraPos'
import ContadorVisitantesList from '../ContadorVisitantesList'
import ReservaContext from '../../../context/ReservaContext'
import useTraeDatos from '../../hooks/useTraeDatos'

const validarEstadoFecha = fecha => {
    let estadoReserva = {
        background: '',
        estado: ''
    };
    const fechaReservaInicial = moment(fecha);
    const fechaHoy = moment(moment().format("YYYY-MM-DD"));
    const diferenciaDiasReservaInicial = fechaReservaInicial.diff(fechaHoy, 'days')

    if (diferenciaDiasReservaInicial === 0) {
        estadoReserva = {
            background: 'APROBADO',
            estado: 'HOY'
        }
    } else if (diferenciaDiasReservaInicial === -1) {
        estadoReserva = {
            background: 'CANCELADO',
            estado: 'AYER'
        }
    } else if (diferenciaDiasReservaInicial === 1) {
        estadoReserva = {
            background: 'PENDIENTE',
            estado: 'MAÑANA'
        }
    } else if (diferenciaDiasReservaInicial < 0) {
        estadoReserva = {
            background: 'CANCELADO',
            estado: fecha
        }
    } else if (diferenciaDiasReservaInicial > 1) {
        estadoReserva = {
            background: 'PENDIENTE',
            estado: fecha
        }
    }
    return estadoReserva
}
const CrearReserva = () => {
    const { aumentarCambioVista, cambiarServiciosTotal } = useContext(ReservaContext)

    const [reservas, setReservas] = useState([])
    const [reservaSeleccionada, setReservaSeleccionada] = useState(null)
    const [paginaSecundaria, setpaginaSecundaria] = useState(true)
    const [inputValue, setInputValue] = useState('')
    const [validarTab, setValidarTab] = useState('')
    const [contadorIngresos, setContadorIngresos] = useState()
    const [totalActual, setTotalActual] = useState(0)
    const [totalServicioGuia, setTotalServicioGuia] = useState(0)
    const [totalServicioVehiculo, setTotalServicioVehiculo] = useState(0)
    const [errorMetodoPago, seterrorMetodoPago] = useState(false)
    const [servicioVehiculos, setServicioVehiculos] = useState([])
    const [totalSeguros, setTotalSeguros] = useState(0)

    const [tiposIngresos] = useTraeDatos(ApiPaths.ingresos);
    const tipoIngreso = tiposIngresos.find((tipo) => tipo.id == '2');

    const [servicios] = useTraeDatos(ApiPaths.servicios + "?tipo_ingreso=" + tipoIngreso?.id);

    const serviciosTransporte = servicios.find(({ tipo }) => tipo === TIPO_SERVICIOS.alquiler);
    const serviciosGuianza = servicios.find(({ tipo }) => tipo === TIPO_SERVICIOS.guianza);
    const [cargandoBusqueda, setCargandoBusqueda] = useState(false)

    const onChangeBuscarInput = async (valor) => {
        try {
            setCargandoBusqueda(true)
            setInputValue(valor)
            const { data } = await axios(ApiPaths.reservas, { params: { buscar: valor } })
            const items = []
            data.map(item => {
                items.push({
                    ...item,
                    estadoReserva: validarEstadoFecha(item.fechaReservaInicial)
                })
            })
            setReservas(items)
            setReservaSeleccionada(null)
            setpaginaSecundaria(true)
            setCargandoBusqueda(false)
        } catch (error) {
            console.log(error)
        }
    }

    const itemSeleccionado = (data) => {
        setReservaSeleccionada({
            estado: data.estado,
            tipoIngreso: {
                nombre: data.tipoingreso,
                senderoMultiple: data.tipoIngresoSenderoMultiple
            },
            personas: JSON.parse(decodeURI(data.personas)),
            fechaReserva: data.fechaReserva,
            fechaReservaInicial: data.fechaReservaInicial,
            fechaReservaFinal: data.fechaReservaFinal,
            serviciosAlimentacion: {
                serviciosAlimentacion: JSON.parse(decodeURI(data.serviciosAlimentacion)),
                contarServicios: JSON.parse(decodeURI(data.contarServicios)),
                totalServicioAlimentacion: parseInt(data.totalServicioAlimentacion),
            },
            servicioVehiculo: JSON.parse(decodeURI(data.servicioVehiculo)),
            servicioGuia: JSON.parse(decodeURI(data.servicioGuia)),
            servicioGenericos: JSON.parse(decodeURI(data.serviciosHospedaje)),
            entradas: data.entradas,
            totalEntradas: parseInt(data.totalEntradas),
            totalServicioVehiculo: parseInt(data.totalServicioVehiculo),
            totalServicioGuia: parseInt(data.totalServicioGuia),
            totalServiciosGenericos: parseInt(data.totalServiciosGenericos),
            totalSeguros: parseInt(data.totalSeguros),
            total: parseInt(data.total),
            transaccionEstado: data.transaccionEstado,
            operadorImagen: data.operadorImagen,
            id: data.id,
            transaccionesEstado: data.transaccionesEstado,
            estadoReserva: validarEstadoFecha(data.fechaReservaInicial),
            codigoReserva: data.codigoReserva,
            senderosDias: data.senderosDias ? JSON.parse(decodeURI(data.senderosDias)) : null,
        })
        setReservas([])
    }

    const verVisitantes = () => {
        setpaginaSecundaria(true)

    }

    const ingresar = async () => {
        try {
            let f = new FormData()
            f.append('id', reservaSeleccionada.id)
            f.append('completado', 'COMPLETADO')
            f.append('METHOD', 'PUT')
            const respuesta = await axios.post(ApiPaths.reservas, f)
            setReservaSeleccionada(null)
            setpaginaSecundaria(true)
            setInputValue('')
            toast.success('Reserva completada con éxito')
        } catch (error) {
            console.log(error)
        }
    }

    const verServicios = () => {
        setpaginaSecundaria(false)
    }

    const calcularEdad = (fecha) => {
        if (fecha.trim() === "") return null;

        const nacimiento = moment(fecha);
        const hoy = moment();
        return hoy.diff(nacimiento, "years");
    }


    const verificarDatosIcon = (dato) => {
        if (dato.trim() === "") return <FontAwesomeIcon icon={faXmark} size="1x" style={{ marginRight: 0, color: 'rgb(255, 204, 0)' }} />

        return <FontAwesomeIcon icon={faCheck} className="textVerde" size="1x" style={{ marginRight: 0 }} />
    }

    const onSubmit = data => {
        if (!data.mediosPago) return seterrorMetodoPago(true)

        cambiarServiciosTotal({
            tipoIngreso,
            entradas: contadorIngresos?.contador,
            tipoEntradas: contadorIngresos?.data,
            tiposEntradas: contadorIngresos,
            seguroMedico: contadorIngresos?.contador,
            totalSeguros: 0,
            totalEntradas: contadorIngresos?.total,
            totalServicioGuia,
            totalServicioVehiculo,
            fechas: [new Date()],
            seguroMedico: contadorIngresos?.contador,
            servicioVehiculos,
            mediosPago: data.mediosPago
        })
        aumentarCambioVista()
    }

    const validarTabSeleccionado = tab => {
        setValidarTab(tab)
        setReservaSeleccionada(null)
        setpaginaSecundaria(true)
        setInputValue('')
    }

    const contarvisitantes = data => {
        setContadorIngresos(data)
        if (data) {
            const totalGuias = parseInt(serviciosGuianza.tarifa) * data.contador;
            const totalVehiculo = parseInt(serviciosTransporte.tarifa);
            const totalSeguros = 15000 * data.contador
            setTotalSeguros(totalSeguros)
            setTotalServicioGuia(totalGuias)
            setTotalServicioVehiculo(totalVehiculo)
            return setTotalActual(data.total + totalGuias + totalVehiculo + totalSeguros)
        }
        setTotalActual(0)
        setServicioVehiculos([{ ...serviciosTransporte, cantidad: 1 }])
    }

    return (
        <div className='reserva-taquilla-view'>
            <div className='reserva-taquilla' style={validarTab === 'compra' ? { order: 2 } : null}>
                <div className='reserva-taquilla-item'>
                    <div className='reserva-taquilla-container'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="ingreso" onSelect={validarTabSeleccionado}>
                            <Nav variant="ingreso" >
                                <Nav.Item >
                                    <Nav.Link className='ingreso' eventKey="ingreso">Ingreso</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link className='compra' eventKey="compra">Compra</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="ingreso">
                                    <Form.Label className='mb-0'>Cedula / Cod Reserva / Nit Operadora</Form.Label>
                                    <InputAutocomplete cargandoBusqueda={cargandoBusqueda} inputValue={inputValue} onChangeBuscarInput={onChangeBuscarInput} dataEncontrada={reservas} itemSeleccionado={itemSeleccionado} />
                                    {reservaSeleccionada && <InformacionReserva data={reservaSeleccionada} verServicios={verServicios} verVisitantes={verVisitantes} ingresar={ingresar} calcularEdad={calcularEdad} verificarDatosIcon={verificarDatosIcon} />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="compra" >
                                    <CrearCompraPos totalSeguros={totalSeguros} totalServicioVehiculo={totalServicioVehiculo} totalServicioGuia={totalServicioGuia} onSubmit={onSubmit} errorMetodoPago={errorMetodoPago} validar={contadorIngresos == null} total={totalActual} seterrorMetodoPago={seterrorMetodoPago} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            {
                reservaSeleccionada &&
                <div className='reserva-taquilla'>

                    {
                        paginaSecundaria ?
                            <div className='reserva-taquilla-informacion'>
                                <InformacionVisitantes reservaSeleccionada={reservaSeleccionada} verificarDatosIcon={verificarDatosIcon} calcularEdad={calcularEdad} />
                            </div>
                            :
                            <div className='reserva-taquilla-informacion'>
                                <InformacionServicios reservaSeleccionada={reservaSeleccionada} />
                            </div>
                    }
                </div>
            }


            {
                validarTab === 'compra' &&
                <div className='reserva-taquilla'>
                    <ContadorVisitantesList
                        tipoEntrada='2'
                        totales={contarvisitantes}
                    />
                </div>
            }
        </div >

    )
}

export default CrearReserva