import { createContext, useCallback, useMemo, useState } from 'react';
const ReservaContext = createContext();

export const ReservaProvider = ({ children }) => {

    const [step, setStep] = useState(1);

    const [serviciosTotal, setServiciosTotal] = useState({})

    const cambiarServiciosTotal = useCallback((data) => {
        setServiciosTotal(data)
    }, []);

    const aumentarCambioVista = () => setStep(step + 1)

    const retrocederCambioVista = () => setStep(step - 1)

    const eliminarServicio = (id) => {
        const servicios = { ...serviciosTotal }
        delete servicios[id]
        setServiciosTotal(servicios)
    }
    const contextValue = useMemo(
        () => ({
            step,
            aumentarCambioVista,
            serviciosTotal,
            retrocederCambioVista,
            cambiarServiciosTotal,
            eliminarServicio
        }), [step, aumentarCambioVista, serviciosTotal, retrocederCambioVista, cambiarServiciosTotal, eliminarServicio],
    );
    return (
        <ReservaContext.Provider value={contextValue}>
            {children}
        </ReservaContext.Provider>
    )
}
export default ReservaContext;