import React, { useState, useEffect, useContext } from 'react'
import Header from '../componentes/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket, faCalendarDays, faCreditCardAlt, faUsers, faUser, faBinoculars, faVanShuttle, faUtensils, faTaxi } from '@fortawesome/free-solid-svg-icons'

import UserContext from '../../context/UserContext';

const EscritorioAdmin = () => {
	const { tiposDeIngreso } = useContext(UserContext);
	const [tituloPag, setTituloPag] = useState("Escritorio")
	const [funcion, setFuncion] = useState('ver')

	const TitulosPagina = ['Ver Reservas', 'Crear Reserva', 'Editar Reserva', 'Eliminar Reserva', 'Detalle de Reserva']

	const Alertas = (alerta, imprimeoNo, cantidad) => {

		let alertaArr = JSON.parse(alerta)

		let Agota = alertaArr[0] // tiene rangofin
		let PocasExis = alertaArr[1]
		let EnExis = alertaArr[2] //tiene rangoini
		//let RangoIni = 
		//console.log('',Agota)
		let resultado = ''

		//AGOTADO:
		//21.000 < 5000

		//console.log('datos',Agota)
		if (cantidad < parseInt(Agota.rangofin)) {
			if (imprimeoNo === 'estado') {
				//resultado = 'Rojo'
				resultado = 'Verde'
			}
		} else if (cantidad >= parseInt(PocasExis.rangoini) && cantidad <= parseInt(PocasExis.rangofin)) {
			if (imprimeoNo === 'estado') {
				resultado = 'Amarillo'
			}
		} else if (cantidad >= parseInt(EnExis.rangoini)) {
			if (imprimeoNo === 'estado') {
				//resultado = 'Azul'
				resultado = 'Rojo'
			}
		} else {
			if (imprimeoNo === 'estado') {
				resultado = 'Blanco'
			}
		}


		return resultado
	}

	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Agregar={false} />
			<br></br>
			<div className='row animated bounceInRight'>
				{tiposDeIngreso.map((item, index) => {
					const total = parseInt((100 * item.capacidadactual) / item.capacidadlimite);
					return (
						<div className={`col-md-12 ItemResumen row ${Alertas(item.alertas, 'estado', item.capacidadactual)}`} key={index}>
							<div className='col-md-1'>
								<FontAwesomeIcon icon={faTicket} size="3x" style={{ marginTop: 0, marginRight: 10 }} />
							</div>
							<div className='col-md-8'>
								<h2>{item.nombre}</h2>
								<p>{item.tipo}</p>
								<p><b>Recaudo: $100.000</b></p>
								<span className={Alertas(item.alertas, 'estado', item.capacidadactual)}></span>
							</div>
							<div className='col-md-3' style={{ textAlign: 'right' }}>
								<p>Límite: {item.capacidadlimite}</p>
								<p>Ocupación</p>
								<h3>{item.capacidadactual} ({total}%)</h3>
							</div>
						</div>
					)
				})}
			</div>
			{/* {funcion === 'ver' ?
				<VerReservas
					data={reservas} 
					seleccionaRegistro={seleccionaRegistro}
					tiposIngresos={tiposIngresos}
					baseUrl={urlReservas}
				/>
			:null} */}
		</div >
	)
}

export default EscritorioAdmin