import React, { useState, useEffect } from 'react'
import VerServicios from './VerServicios'
import SubirEditServicio from './SubirEditServicio'
import Eliminar from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import { Titulos, peticionDelete } from '../hooks/Funciones'

import { ApiPaths } from '../../utils';

const ServiciosAdmin = () => {

	const [tituloPag, setTituloPag] = useState("Servicios")
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [servicios, setServicios] = useTraeDatos(ApiPaths.servicios)
	const TitulosPagina = ['Ver Servicios', 'Crear Servicio', 'Editar Servicio', 'Eliminar Servicio', 'Servicios']

	const seleccionaRegistro = (dato, funcion) => {
		setRegistroSelect({ ...dato, imagePrev: dato.imagen })
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () => { peticionDelete(registroSelect, ApiPaths.servicios, servicios, setServicios, setFuncion) }
	useEffect(() => { Titulos(funcion, setTituloPag, TitulosPagina) }, [funcion])


	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'} Agregar={true} />
			{funcion === 'crear' ?
				<SubirEditServicio
					baseUrl={ApiPaths.servicios}
					setFuncion={setFuncion}
					data={servicios}
					setData={setServicios}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag}
					urlIngresos={ApiPaths.ingresos}
					funcion={funcion} />

				: null}
			{funcion === 'editar' ?
				<SubirEditServicio
					baseUrl={ApiPaths.servicios}
					setFuncion={setFuncion}
					data={servicios}
					setData={setServicios}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag}
					urlIngresos={ApiPaths.ingresos}
					registroSelect={registroSelect}
					funcion={funcion} />
				: null}
			{funcion === 'ver' ?
				<VerServicios
					data={servicios}
					seleccionaRegistro={seleccionaRegistro}
					setFuncion={setFuncion}
					setRegistroSelect={setRegistroSelect}
					baseUrl={ApiPaths.servicios}
				/>
				: null}
			{funcion === 'eliminar' ?
				<Eliminar
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee} />

				: null}
		</div>
	)
}

export default ServiciosAdmin