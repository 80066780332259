import React, { useState, useEffect } from 'react'
import VerGuias from './VerGuias'
import SubirEditGuias from './SubirEditGuias'
import Eliminar from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import { Titulos, peticionDelete } from '../hooks/Funciones'

import { ApiPaths } from '../../utils';

const GuiasAdmin = () => {

	const [tituloPag, setTituloPag] = useState("Guias")
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [guias, setGuias] = useTraeDatos(ApiPaths.guias)
	const [tiposIngresos, setTiposIngresos] = useTraeDatos(ApiPaths.ingresos)
	const TitulosPagina = ['Ver Guias', 'Crear Guia', 'Editar Guia', 'Eliminar Guia', 'Guias']

	const seleccionaRegistro = (dato, funcion) => {
		setRegistroSelect({ ...dato, imagePrev: dato.imagen })
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () => { peticionDelete(registroSelect, ApiPaths.guias, guias, setGuias, setFuncion) }
	useEffect(() => { Titulos(funcion, setTituloPag, TitulosPagina) }, [funcion])


	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'} Agregar={true} />
			{funcion === 'crear' ?
				<SubirEditGuias
					baseUrl={ApiPaths.guias}
					setFuncion={setFuncion}
					data={guias}
					setData={setGuias}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag}
					urlIngresos={ApiPaths.ingresos}
					funcion={funcion} />

				: null}
			{funcion === 'editar' ?
				<SubirEditGuias
					baseUrl={ApiPaths.guias}
					setFuncion={setFuncion}
					data={guias}
					setData={setGuias}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag}
					urlIngresos={ApiPaths.ingresos}
					registroSelect={registroSelect}
					funcion={funcion} />
				: null}
			{funcion === 'ver' ?
				<VerGuias
					data={guias}
					seleccionaRegistro={seleccionaRegistro}
					tiposIngresos={tiposIngresos}
					baseUrl={ApiPaths.guias}
				/>
				: null}
			{funcion === 'eliminar' ?
				<Eliminar
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee} />

				: null}
		</div>
	)
}

export default GuiasAdmin