import React, { useContext, useRef } from 'react'
import { Controller, useForm } from "react-hook-form";

import ReservaContext from '../../../context/ReservaContext'
import TablaResumen from '../../reservas/TablaResumen'
import CampoPersonas from '../../reservas/CampoPersonas'
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const Visitantes = () => {
    const { serviciosTotal, retrocederCambioVista, step, cambiarServiciosTotal, aumentarCambioVista } = useContext(ReservaContext);
    const latestServiciosTotalRef = useRef(serviciosTotal); // Referencia mutable para almacenar el estado actualizado

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            personas: serviciosTotal.personas
        }
    });

    const updateServicioTotal = (data) => {
        latestServiciosTotalRef.current = { ...latestServiciosTotalRef.current, ...data }; // Actualiza la referencia mutable
        cambiarServiciosTotal({ ...latestServiciosTotalRef.current }); // Actualiza el estado
    };

    const onSubmit = (data) => {
        let totalSeguros = 0;
        data.personas.forEach(item => {
            if (item.codigoSeguro.trim().length === 0) {
                totalSeguros += 1;
            }
        });

        if (totalSeguros > 0) {
            toast.info("Cada póliza por persona tiene un costo adicional de $5.000");
        }

        aumentarCambioVista();

        cambiarServiciosTotal(prevServiciosTotal => ({
            ...latestServiciosTotalRef.current,
            personas: data.personas,
            totalSeguros: 5000 * totalSeguros
        }));
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-8'>
                        <Controller
                            control={control}
                            name="personas"
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                            }) => (
                                <CampoPersonas
                                    errors={errors}
                                    serviciosTotal={serviciosTotal}
                                    actualizarServiciosGeneralesTotal={onChange}
                                    updateServicioTotal={updateServicioTotal}
                                    value={value}
                                />
                            )}
                        />


                    </div>
                    <div className='col-md-4'>
                        <TablaResumen
                            serviciosTotal={serviciosTotal}
                            botonAtras={retrocederCambioVista}
                            step={step}
                        />
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default Visitantes