import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import md5 from 'md5'
import { useNavigate } from 'react-router-dom';
import * as RUTAS from '../constantes/rutas';
import UserContext from '../context/UserContext.js'
import { VISITANTE } from '../constantes/roles.js';
import { ApiPaths } from '../utils.js';
import Logo from '../paginas/img/logo.png'
import { NavLink } from 'react-router-dom';
import ImagenLogo from '../paginas/img/logoTecnosoluciones.png';
import { Image } from 'react-bootstrap';
import '../paginas/estilos/estilos.css'
import moment from 'moment';

const RecuperarContrasena = () => {

	const sede = "Parque Nevados"

	const baseUrlUsers = ApiPaths.tickets;
	const [form, setForm] = useState({ correo: '' })
	const [errorForm, setErrorForm] = useState('')

	const navigate = useNavigate();


	//función click
	const onSubmit = async e => {
		try {
			e.preventDefault()
			let formData = new FormData()
			formData.append('token', moment().format("MMDDYYYYhhmmssSSS"))
			formData.append('fecha', moment().format("YYYY-MM-DD HH:mm:ss"))
			formData.append('correo', form.correo)
			formData.append('METHOD', 'POST')
			const respuesta = await axios.post(baseUrlUsers, formData)
			setErrorForm('Se ha enviado un link al correo ingresado para recuperar la contraseña')
			setForm({ correo: '' })
		} catch (error) {
			return setErrorForm(error.response.data.error)
		}
	}

	//función escucha form
	const onChange = e => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}


	return (
		<div className="Login">

			<div className="card align-self-center text-center container">
				<div className="card-body px-lg-5 pt-0">

					<img className="Logo_login" src={Logo} />

					<form className="text-center" onSubmit={onSubmit}>

						<div className="md-form">
							<input
								className="form-control"
								name="correo"
								type="email"
								placeholder="Correo Electronico"
								onChange={onChange}
								value={form.correo}
							/>
						</div>

						<p className={errorForm !== '' ? 'animated bounceInRight' : ''}>{errorForm}</p>
						<button type="submit" className="btn boton_estandar btn-rounded btn-block my-4 btn-lg">Recuperar Contraseña</button>
						<span>Sede: {sede}</span>
					</form>

					<div className='login-links-alternos-logo'>
						<p>Desarrollado por:</p>
						<Image
							src={ImagenLogo}
						/>

					</div>

				</div>
			</div>
		</div>
	)
}



export default RecuperarContrasena