// External libraries
import React, { useContext, useState } from 'react'
import ReservaContext from '../../context/ReservaContext';
import Calendario from './Steps/Calendario';
import Visitantes from './Steps/Visitantes';
import TransporteGuianza from './Steps/TransporteGuianza';
import AlimentacionHospedaje from './Steps/AlimentacionHospedaje';
import { InformacionPago } from './InformacionPago';
import Stepper from '../componentes/stepper'
import UserContext from '../../context/UserContext';
import { TAQUILLA } from '../../constantes/roles';
import CrearReserva from './Taquilla/CrearReserva';
import CrearUsuario from './reservaVisitante/CrearUsuario';

const SubirReservas = () => {
	const { user } = useContext(UserContext);
	const { step } = useContext(ReservaContext);

	if (user && parseInt(user.roll) === TAQUILLA) {
		switch (step) {
			case 1:
				return <>
					<CrearReserva />
				</>
			case 2:
				return <>
					<Visitantes />
				</>

			case 3:
				return <>
					<InformacionPago />
				</>
		}
	} else {
		if (user) {
			const stepperData = [
				{
					step: 1,
					nombre: 'Datos Reserva'
				},
				{
					step: 2,
					nombre: 'Añade personas'
				},
				{
					step: 3,
					nombre: 'Servicios Uton '
				}, {
					step: 4,
					nombre: 'Servicios Uton '
				},
				{
					step: 5,
					nombre: 'Resumen Reserva'
				}
			]
			switch (step) {
				case 1:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<Calendario />
					</>
				case 2:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<Visitantes />
					</>
				case 3:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<TransporteGuianza />
					</>
				case 4:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<AlimentacionHospedaje />
					</>
				case 5:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<InformacionPago />
					</>
			}
		} else {
			const stepperData = [
				{
					step: 1,
					nombre: 'Datos Reserva'
				},
				{
					step: 2,
					nombre: 'Añade personas'
				},
				{
					step: 3,
					nombre: 'Servicios Uton '
				}, {
					step: 4,
					nombre: 'Crear usuario'
				},
				{
					step: 5,
					nombre: 'Resumen Reserva'
				}
			]
			switch (step) {
				case 1:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<Calendario />
					</>
				case 2:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<Visitantes />
					</>
				case 3:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<TransporteGuianza />
					</>
				case 4:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<CrearUsuario />
					</>
				case 5:
					return <>
						<Stepper data={stepperData} stepActual={step} />
						<InformacionPago />
					</>
			}
		}

	}


}

export default SubirReservas