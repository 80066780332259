import React, {useState, useEffect} from 'react'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos, peticionDelete} from '../hooks/Funciones'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFloppyDisk, faRefresh, faCreditCardAlt, faUsers, faUser, faBinoculars, faVanShuttle, faUtensils, faTaxi} from '@fortawesome/free-solid-svg-icons'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';

import { ApiPaths } from '../../utils';


const ConfigAdmin = () => {

	const [tituloPag, setTituloPag] = useState("Configuraciones")
	const [funcion, setFuncion] = useState('ver')
	//const [reservas, setReservas] = useTraeDatos(urlReservas)
	const [tiposIngresos, setTiposIngresos] = useTraeDatos(ApiPaths.ingresos)
	const TitulosPagina = ['Ver Reservas', 'Crear Reserva', 'Editar Reserva', 'Eliminar Reserva', 'Detalle de Reserva']
	const [registro, setRegistro] = useState({
		baseurl: 'localhost:8888//',
		nombrePag: 'Unión Temporal Nevados',
		codFactura: 'RESE',
		codFacturaTaq: 'TAQ',
		pagina: 'www.operacionnevados.com',
		contacto: '+57 321 506 6031',
		notasFinalCompra: 'Gracias por su compra, cualquier duda comunicarse a nuestras lineas'
	})

	const handleClearCache = () => {
		if ('caches' in window) {
		// Borrar todas las caches
		caches.keys().then((names) => {
			names.forEach((name) => {
			caches.delete(name);
			});
		});
		}
		
		// Recargar la página forzando la recarga desde el servidor
		window.location.reload(true);
	};


	const SubeData = e => {
		setRegistro({...registro, [e.target.name] : e.target.value})
	}

	return(
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Agregar={false}/>
			<br></br>
			<div className="row" style={{fontSize: 16}}>
				<div className="col-md-12">
					<MDBInput label="BaseUrl" outline value={registro.baseurl} name='baseurl' onChange={e => SubeData(e)}/>
					<MDBInput label="Nombre Página" outline value={registro.nombrePag} name='nombrePag' onChange={e => SubeData(e)} />
					<MDBInput label="Código Factura Digital" outline value={registro.codFactura} name='codFactura' onChange={e => SubeData(e)} />
					<MDBInput label="Código Factura Taquilla" outline value={registro.codFacturaTaq} name='codFacturaTaq' onChange={e => SubeData(e)} />
					<MDBInput label="Página web software" outline value={registro.pagina} name='pagina' onChange={e => SubeData(e)} />
					<MDBInput label="Lineas de Contacto" outline value={registro.contacto} name='contacto' onChange={e => SubeData(e)} />
					<MDBInput label="Notas Finalización de la compra" outline value={registro.notasFinalCompra} name='notasFinalCompra' onChange={e => SubeData(e)} />
				
					<button 
						onClick={e => alert('Configuración Guardada')}
						className="btn boton_estandar"							
						><FontAwesomeIcon icon={faFloppyDisk} size="lg"/>  Guardar Configuración
					</button>
					<button 
						onClick={handleClearCache}
						className="btn boton_estandar"							
						><FontAwesomeIcon icon={faRefresh} size="lg"/>  Limpiar Cache
					</button>

					
				</div>
			</div>
		</div>
	)
}

export default ConfigAdmin