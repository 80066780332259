import React, { useState, useEffect } from 'react'
//import NumberFormat from 'react-number-format';
import axios from 'axios'
import VerOperadores from './VerOperadores'
import EditarOperadores from './EditarOperadores'
import SubirOperador from './SubirOperador'
import Eliminar from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';

import { ApiPaths } from '../../utils';
import VerAbonos from './VerAbonos';
import SubirAbonos from './SubirAbonos'
import EditarAbonos from './EditarAbonos'

const OperadoresAdmin = () => {

	const [tituloPag, setTituloPag] = useState("Operadores")
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [operadores, setOperadores] = useTraeDatos(ApiPaths.operadores)


	const seleccionaRegistro = (dato, funcion) => {
		setRegistroSelect({ ...dato, imagePrev: dato.imagen })
		setFuncion(funcion)
	}

	const peticionDelete = async () => {

		console.log('id_elemento', registroSelect.id)
		let f = new FormData();
		f.append("METHOD", "DELETE");
		await axios.post(ApiPaths.operadores, f, { params: { id: registroSelect.id, imagen: registroSelect.imagen } })
			.then(response => {
				setOperadores(operadores.filter(opera => opera.id !== registroSelect.id))
				console.log('respuesta detele', response)
				setFuncion('ver')
			}).catch(error => { console.log(error) })
	}

	useEffect(() => {
		switch (funcion) {
			case 'ver': return setTituloPag('Ver Operadores')
			case 'crear': return setTituloPag('Crear Operador')
			case 'editar': return setTituloPag('Editar Operador')
			case 'crearAbonos': return setTituloPag('Agregar Abonos Operador')
			case 'editarAbonos': return setTituloPag('Editar Abonos Operador')
			case 'eliminar': return setTituloPag('Eliminar Operador')
			default: return setTituloPag('Operadores')
		}
	}, [funcion])


	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'} Agregar={true} />

			{
				{
					'crear': <SubirOperador
						baseUrl={ApiPaths.operadores}
						setFuncion={setFuncion}
						operadores={operadores}
						setOperadores={setOperadores}
						Titulo={'Subir Operador'}
						setTituloPag={setTituloPag}
					/>,
					'editar': <EditarOperadores
						baseUrl={ApiPaths.operadores}
						setFuncion={setFuncion}
						operadores={operadores}
						setOperadores={setOperadores}
						Titulo={'Editar Operador'}
						registroSelect={registroSelect}
						setRegistroSelect={setRegistroSelect}
						setTituloPag={setTituloPag}
					/>,
					'ver': <VerOperadores
						data={operadores}
						urlOperadores={ApiPaths.operadores}
						seleccionaRegistro={seleccionaRegistro}
					/>,
					'eliminar': <Eliminar
						nombre={'Operador'}
						select={registroSelect}
						setFuncion={setFuncion}
						peticionDelete={peticionDelete}
					/>,
					'abonos': <VerAbonos
						select={registroSelect}
						baseUrl={ApiPaths.operadoresAbonos}
						seleccionaRegistro={seleccionaRegistro}
					/>,
					'crearAbonos': <SubirAbonos
						baseUrl={ApiPaths.operadoresAbonos}
						setFuncion={setFuncion}
						select={registroSelect}
						seleccionaRegistro={seleccionaRegistro}
					/>,
					'editarAbonos': <EditarAbonos
						baseUrl={ApiPaths.operadoresAbonos}
						setFuncion={setFuncion}
						select={registroSelect}
						registroSelect={registroSelect}
						setRegistroSelect={setRegistroSelect}
					/>,
				}[funcion]
			}
		</div>
	)
}

export default OperadoresAdmin